import * as React from 'react';
import * as moment from 'moment';
import { NavLink } from 'react-router-dom';
import { motion, MotionProps, AnimationProps } from 'framer-motion';
import { DynLabel, AppTextLabel } from '@@services/i18nservice';
import { getPageFor } from '@@community/features/contents/data.content';
import { DateRange } from '@@components/templates/daterange';
import type { ICommunityEvent, ICommunityWebinar } from '@@community/data';
import type { IBlocCommunitySpotlightsProps, ISpotlightItem } from './index';

const animateItemsInitialFromRight: MotionProps["initial"] = {
    translateX: 200,
    opacity: 0,
};
const animateItemsInitialFromLeft: MotionProps["initial"] = {
    translateX: -200,
    opacity: 0,
};
const animateItemsAnimate: AnimationProps["animate"] = {
    translateX: 0,
    opacity: 1
};

const animateItemsTransition: AnimationProps["transition"] = {
    duration: 0.4,
};

const animateItemsExitToLeft: AnimationProps["exit"] = {
    translateX: -100,
    opacity: 0
};
const animateItemsExitToRight: AnimationProps["exit"] = {
    translateX: 100,
    opacity: 0
};
export interface ISpotlightsItemProps extends IBlocCommunitySpotlightsProps {
    item: ISpotlightItem;
    move: number;
    titleMax: number;
    descMax: number;
    prevBtn: any;
    nextBtn: any;
}

export class SpotlightItem extends React.PureComponent<ISpotlightsItemProps, any> {
    render() {
        const content = this.props.item;
        let bg = null;
        let dates = null;

        if (content.herobanner) {
            bg = <div className="spotlightitembg">
                <div className="picture" style={{ backgroundImage: 'url("' + content.herobanner.url + '")' }} />
                <div className="filter" />
            </div>;
        }

        const target = getPageFor(this.props.item.contentType, this.props.item.id);
        if (this.props.item.contentType === "IWEvent" || this.props.item.contentType === "Event") {
            const itm: ICommunityWebinar = this.props.item as any;
            if (itm.startDate && !itm.$startDate) {
                itm.$startDate = moment(itm.startDate);
            }
            if (itm.endDate && !itm.$endDate) {
                itm.$endDate = moment(itm.endDate);
            }
            dates = <h4 className="dates"><DateRange startDate={itm.$startDate} endDate={itm.$endDate} /></h4>;
        }

        let link = <NavLink to={this.props.urlservice.pageUrl(target)} role="button">
            <AppTextLabel i18n={"community.content." + this.props.item.contentType.toLowerCase() + ".go"} />
        </NavLink>;

        if (this.props.item.contentType === "Event") {
            const evt: ICommunityEvent = this.props.item as any;
            if (evt.computedUrl) {
                link = <NavLink to={evt.computedUrl} role="button" target="_blank">
                    <AppTextLabel i18n={"community.content." + this.props.item.contentType.toLowerCase() + ".go"} />
                </NavLink>;
            }
        }

        return <motion.div
            className="spotlightitem"
            initial={this.props.move < 0 ? animateItemsInitialFromLeft : animateItemsInitialFromRight}
            animate={animateItemsAnimate}
            transition={animateItemsTransition}
            exit={this.props.move < 0 ? animateItemsExitToRight : animateItemsExitToLeft}
        >
            {bg}
            <div className="spotlightitem-content">
                {this.props.prevBtn}
                <div className="spotlightitem-detail">
                    <DynLabel i18n={content.title} component="h3" maxLength={this.props.titleMax} />
                    {dates}
                    <DynLabel i18n={content.description} component="p" maxLength={this.props.descMax} />
                    <div className="spotlightitem-action">
                        {link}
                    </div>
                </div>
                {this.props.nextBtn}
            </div>
        </motion.div>;
    }
}

// eslint-disable-next-line prefer-arrow-callback
export const Placeholder = React.memo(function PlaceHolder(props: { titleMax: number; descMax: number; }) {
    const content = {
        title: { en: "lorem ipsum" },
        description: { en: "lorem ipsum" }
    };

    let buffer = [];
    for (let i = 0; i < props.titleMax; i++) {
        buffer.push(i % 5 === 0 ? ' ' : '0');
    }
    content.title.en = buffer.join('');
    buffer = [];
    for (let i = 0; i < props.descMax; i++) {
        buffer.push(i % 5 === 0 ? ' ' : '0');
    }
    content.description.en = buffer.join('');

    return <div className="spotlightitem placeholder">
        <div className="spotlightitem-content">
            <div className="spotlightitem-detail">
                <h3>{content.title.en}<div className="navette" /></h3>
                <h4 className="dates">&nbsp;</h4>
                <p>{content.description.en}<span className="navette" /></p>
                <div className="spotlightitem-action">
                    <button type="button" disabled>&nbsp; <div className="navette" /></button>
                </div>
            </div>
        </div>
    </div>;
});

import * as React from 'react';
import { connect } from 'react-redux';
import { withI18nHelper } from '@inwink/i18n';
import type { Entities } from '@inwink/entities/entities';
import type { ItemTemplateProps } from '@@components/templates/itemtemplate.props';
import type { States } from '@@services/services';
import { wrapReduxStore, IInwinkStore } from '@@store/index';
import { userMessageActions } from '../../../../../services/usermessageactions';
import { bindActionCreators } from 'redux';
import { deleteForumSubject, PinForumSubject, UnpinForumSubject } from '@@modules/community/api/forumsubjects';
import { templatedString } from '@inwink/utils/methods/templatedString';
import { withRouter } from 'react-router-dom';
import * as History from 'history';
import { actions as pageActions } from '@@services/pageservice';


import { MoreActions } from '@@modules/community/components/contextmenu/moreactions';
import { ICommunityForumSubject } from '@@modules/community/data';
import { ICreateForumSubjectProps } from '@@community/features/forums/modals/createforumsubjectmodal';

import './forumsubjectactions.less';

export interface IForumSubjectActionsProps extends ItemTemplateProps {
    history: History.History;
    i18n?: States.i18nState;
    community?: States.ICommunityState;
    i18nHelper?: Entities.i18nHelper;
    store?: IInwinkStore;
    user: States.IAppCurrentUser;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    __user: States.IAppUserState
    userMessageActions?: typeof userMessageActions;
    data?: any;
    location: States.ILocation;
    match: States.ILocationMatch;
    pageActions: typeof pageActions;
}

interface IForumSubjectActionsState {
    pinned: boolean;
}

@withI18nHelper()
class ForumSubjectActionsComponent extends React.Component<IForumSubjectActionsProps, IForumSubjectActionsState> {
    constructor(props: IForumSubjectActionsProps) {
        super(props);
        this.state = {
            pinned: props.data?.pinned
        };
    }

    getForumSubjectUrl = (forumSubject: ICommunityForumSubject) => {
        const searchArgs = this.props.location?.search || '';
        if (this.props.template.properties && this.props.template.properties.targetPage) {
            return this.props.urlservice.pageUrl(`${templatedString(this.props.template.properties.targetPage,
                { ...this.props.datacontext, entity: forumSubject })}${searchArgs}`);
        }

        return this.props.urlservice.pageUrl(`forums/${forumSubject.forumId}/subjects/${forumSubject.id}${searchArgs}`);
    };

    getActions = () => {
        return [
            {
                icon: "inwink-push-pin",
                title: "community.feed.actions.pin",
                showIf: (data) => {
                    return !data.data.pinned && this.props.user?.member?.membershipLevel?.configuration?.isModerator;
                },
                callback: () => {
                    PinForumSubject(this.props.community.requestManagers.apiFront,
                        this.props.data.forumId, this.props.data.id, this.props.user?.member).then(() => {
                        this.props.data.pinned = true;
                        this.props.onDataUpdate(this.props.data);
                    }, (err) => {
                        console.error("error updating publication", err);
                    });
                }
            },
            {
                icon: "inwink-push-pin",
                title: "community.feed.actions.unpin",
                showIf: (data) => {
                    return data.data.pinned && this.props.user?.member?.membershipLevel?.configuration?.isModerator;
                },
                callback: () => {
                    UnpinForumSubject(this.props.community.requestManagers.apiFront,
                        this.props.data.forumId, this.props.data.id, this.props.user?.member).then(() => {
                        this.props.data.pinned = false;
                        this.props.onDataUpdate(this.props.data);
                    }, (err) => {
                        console.error("error updating publication", err);
                    });
                }
            },
            {
                icon: "inwink-edit",
                title: "community.feed.actions.updatepublication",
                showIf: () => {
                    return this.props.user?.detail.id === this.props.data.memberId;
                },
                callback: () => {
                    return import('@@community/features/forums/modals/createforumsubjectmodal').then((mod) => {
                        const props: ICreateForumSubjectProps = {
                            datacontext: this.props.datacontext,
                            forumId: this.props.data?.forumId,
                            groupId: this.props.match.params.groupid,
                            title: this.props.data?.title,
                            description: this.props.data?.description,
                            tags: this.props.data?.forumTags,
                            categories: this.props.data?.categorySubjects,
                            i18nHelper: this.props.i18nHelper,
                            community: this.props.community,
                            user: this.props.__user,
                            forumSubjectId: this.props.data?.info?.forumSubjectId ||
                            this.props.data?.forum.forumSubjects?.[0].id
                        };

                        return (userMessageActions.showModal(this.props.i18nHelper,
                            mod.CreateForumSubject, "community.forum.editsubject",
                            props, "community-createforumsubjectmodal"
                        )(this.props.store.dispatch, this.props.store.getState) as any).then((res) => {
                            if (res) {
                                this.props.onDataUpdate(res);
                                this.props.pageActions?.refreshCurrentPageEntity();
                            }
                        });
                    });
                }
            },
            {
                icon: "inwink-box-checked",
                title: "community.feed.actions.category",
                showIf: () => {
                    return this.props.user
                        && (this.props.user.member?.membershipLevel?.configuration?.isModerator
                            || this.props.datacontext?.customcontext?.groupMember?.isModerator);
                },
                callback: () => {
                    return import('@@community/features/forums/modals/createforumsubjectmodal').then((mod) => {
                        const props: ICreateForumSubjectProps = {
                            addCategory: true,
                            datacontext: this.props.datacontext,
                            forumId: this.props.data?.forumId,
                            title: this.props.data?.title,
                            description: this.props.data?.description,
                            tags: this.props.data?.forumTags,
                            categories: this.props.data?.categorySubjects,
                            i18nHelper: this.props.i18nHelper,
                            community: this.props.community,
                            user: this.props.__user,
                            forumSubjectId: this.props.data?.info?.forumSubjectId ||
                                this.props.data?.forum.forumSubjects?.[0].id
                        };

                        return (userMessageActions.showModal(this.props.i18nHelper,
                            mod.CreateForumSubject, "community.forum.category",
                            props, "community-createforumsubjectmodal"
                        )(this.props.store.dispatch, this.props.store.getState) as any).then((res) => {
                            if (res) {
                                this.props.onDataUpdate(res);
                                this.props.pageActions?.refreshCurrentPageEntity();
                            }
                        });
                    });
                }
            },
            {
                icon: "inwink-trash",
                title: "community.feed.actions.deletepublication",
                showIf: () => {
                    return this.props.user && (
                        this.props.user.detail.id === this.props.data.memberId ||
                        this.props.user.member?.membershipLevel?.configuration?.isModerator
                    );
                },
                callback: () => {
                    return (userMessageActions.confirm(this.props.i18nHelper, {
                        title: this.props.i18nHelper.translate("community.forum.subject.remove.confirm"),
                        message: this.props.i18nHelper.translate("community.forum.subject.remove.confirm.message"),
                    })(this.props.store.dispatch, this.props.store.getState) as any).then((confirmed) => {
                        if (confirmed) {
                            deleteForumSubject(
                                this.props.community.requestManagers.apiFront,
                                this.props.data?.forumId,
                                this.props.data?.info?.forumSubjectId ||
                                this.props.data?.forum.forumSubjects?.[0].id
                            ).then((res) => {
                                this.props.onDataUpdate(res);
                                this.props.history.push(
                                    this.props.urlservice.pageUrl(`forums/${this.props.data?.forumId}`)
                                );
                            }, (err) => {
                                console.error("error deleting forum subject", err);
                            });
                        }
                    });

                }
            }
        ];
    };


    render() {
        return <MoreActions data={this.props} items={this.getActions()} />;
    }
}

function mapStateToProps(state: States.IAppState) {
    return {
        community: state.community
    };
}

function mapDispatchToProps(dispatch) {
    return {
        userMessageActions: bindActionCreators(userMessageActions, dispatch)
    };
}

export const ForumSubjectActions: new (any)
=> React.Component<IForumSubjectActionsProps, any> = connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(wrapReduxStore(ForumSubjectActionsComponent) as any)) as any;


import * as React from 'react';
import { AsyncButton } from '@inwink/buttons/asyncbutton';
import { IEntityDetailActionProps } from '@@pages/entitydetail/entitydetail.actions';
import { removeBookmark } from '@@community/api/contents';
import type { ICommunityContent } from '@@community/data';
import { AppTextLabel } from '@inwink/i18n';
import { actions as pageActions } from '@@services/pageservice';
import { connectwith } from '@inwink/react-utils/decorators/connectwith';
import { bindActionCreators } from 'redux';


interface IBookmarkProps extends IEntityDetailActionProps {
}

@connectwith(() => {
    return {
    };
}, (dispatch) => {
    return {
        pageActions: bindActionCreators(pageActions, dispatch),
    };
})

export class UnBookmarkContent extends React.PureComponent<IBookmarkProps, any> {
    render() {
        const content: ICommunityContent = this.props.datacontext.entity;
        const onClick = (arg: React.MouseEvent<any>) => {
            arg.preventDefault();
    
            if (content.isBookmarked) {
                return removeBookmark(
                    this.props.community.requestManagers.apiFront, this.props.entityKind as any, this.props.entityId
                ).then((res) => {
                    if (res?.bookmarked) {
                        this.props.pageActions.patchCurrentPageEntity({ isBookmarked: false });
                    }
                }, (err) => {
                    console.error("error removing bookmark", err);
                });
            }
        };    
    
        if (content.isBookmarked) {
            const classNames = ["checked unbookmark"];

            if (this.props.action?.displayOptions?.isSecondary) {
                classNames.push('lightbtn');
            }
            
            return <AsyncButton className={classNames?.join(' ')} onClick={onClick}>
                {this.props?.action?.icon?.className
                    ? <i className={this.props?.action?.icon?.className} />
                    : null}
                <AppTextLabel i18n={
                    this.props?.i18nHelper?.translateBag(this.props?.action?.label)
                    || "community.content.actions.unbookmark"} />
            </AsyncButton>;
        }
    
        return null;
    }
}

import * as React from 'react';
import type { ItemTemplateProps } from '@@components/templates/itemtemplate.props';

export interface IInfoLikesProps extends ItemTemplateProps {

}

export class InfoLikes extends React.PureComponent<IInfoLikesProps, any> {
    render() {
        if (!this.props.data?.info?.likes) {
            return null;
        }

        return <span className="infolikes">
            <i className="inwink-thumbup-outline" /> {this.props.data?.info?.likes}
        </span>;
    }
}

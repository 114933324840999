import * as React from 'react';
import { bindActionCreators } from 'redux';
import { Entities } from '@inwink/entities/entities';
import { withI18nHelper } from '@inwink/i18n/reactcontext';
import { connectwith } from '@inwink/react-utils/decorators/connectwith';
import { userMessageActions } from '@@services/usermessageactions';
import { MemberInfoContext } from '@@community/components/memberinfocontext';
import type { ICommentsDatasource, IPublication } from '@@community/data';
import { AppTextLabel } from '@inwink/i18n/apptextlabel';
import { CommentsItems } from './commentitems';
import { SendComment } from './sendcomment';
import { States } from '@@services/services';

import "./commentslist.less";

export interface ICommentsListProps {
    i18nHelper?: Entities.i18nHelper;
    showAddComment: boolean;
    datasource: ICommentsDatasource;
    community: States.ICommunityState;
    datacontext: Entities.IPageDataContext;
    userMessageActions?: typeof userMessageActions;
    hideReplies?: boolean;
    hideActions?: boolean;
    onAddComment?: () => Promise<any> | void;
    groupId?: string;
    isPublicGroup?: boolean;
    user?: States.IAppUserState;
    usePagination?: boolean;
    publication?: IPublication;
}

export interface ICommentsListState {
    stamp: any;
}

@connectwith(null, (dispatch) => {
    return {
        userMessageActions: bindActionCreators(userMessageActions, dispatch),
    };
})
@withI18nHelper()
export class CommentsList extends React.PureComponent<ICommentsListProps, ICommentsListState> {
    constructor(props) {
        super(props);
        this.state = {
            stamp: new Date()
        };
    }

    commentsChanged = () => {
        this.setState({ stamp: new Date() });
    };

    render() {
        let addComment = null;

        if (this.props.showAddComment) {
            addComment = <SendComment
                datacontext={this.props.datacontext}
                datasource={this.props.datasource}
                i18nHelper={this.props.i18nHelper}
                onAddComment={this.props.onAddComment}
                onCommentsChanged={this.commentsChanged}
                user={this.props.user}
                groupId={this.props.groupId}
                bigComments
            />;
        }

        return <div className="iw-communitycomments list-comments">
            <div className="addcomment-wrapper">
                <AppTextLabel
                    component="h4"
                    i18n={`community.${this.props.datasource.i18nEntityName || "comment"}.add.title`}
                    className="list-title"
                />
                {addComment}
            </div>
            <div className="commentfeed-wrapper">
                <MemberInfoContext.Consumer>
                    {(memberInfoContext) => <CommentsItems
                        user={this.props.user}
                        community={this.props.community}
                        datacontext={this.props.datacontext}
                        publication={this.props.publication}
                        datasource={this.props.datasource}
                        memberInfoContext={memberInfoContext}
                        onCommentsChanged={this.commentsChanged}
                        userMessageActions={this.props.userMessageActions}
                        i18nHelper={this.props.i18nHelper}
                        changestamp={this.state.stamp}
                        hideActions={this.props.hideActions}
                        hideReplies={this.props.hideReplies}
                        usePagination={this.props.usePagination}
                        bigComments
                    />}
                </MemberInfoContext.Consumer>
            </div>

        </div>;
    }
}

import * as React from 'react';
import { IEntityDetailActionProps } from '@@pages/entitydetail/entitydetail.actions';
import type { ICommunityIWEvent } from '@@community/data';

interface IEventLocationProps extends IEntityDetailActionProps {
}

export function EventLocation(props: IEventLocationProps) {
    const content: ICommunityIWEvent = props.datacontext.entity;

    if (!content || !content.location) {
        return null;
    }

    const classNames = ["location"];

    if (props.action?.displayOptions?.isSecondary) {
        classNames.push('lightbtn');
    }

    return <a
        role="button"
        target="_blank"
        rel="noreferrer"
        href={'https://maps.google.com/?q=' + content.location.replace('\n', ' ')}
        className={classNames?.join(' ')}
    >
        <span className="text">
            <i className={props.action.icon.className || "inwink-map"} />
            {props.action.label?.[props.i18nHelper.i18n.currentLanguageCode]}
        </span>
    </a>;
}

import * as assign from 'lodash/assign';
import * as range from 'lodash/range';
import * as React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { VisualTheme } from '@inwink/entities/visualtheme';
import { parse, stringify } from '@inwink/utils/querystring';
import { AppTextLabel, DynLabel } from '../../../services/i18nservice';
import type { Entities } from '@inwink/entities/entities';
import { withI18nHelper } from '@inwink/i18n';
import { Loader } from '@inwink/loader';

export interface IPagerProps {
    pageCount: number;
    totalCount: number;
    currentPage: number;
    pageSize: number;
    swipedTo: (page: number) => void;
    pageSizeChanged?: (size: number) => void;
    pagerUrl?: string;
    loading?: boolean;
    counterLabel?: string | VisualTheme.IAppLabel;
    pageSizeLabel?: string | VisualTheme.IAppLabel;
    hideCounter?: boolean;
    location?: any;
    i18nHelper?: Entities.i18nHelper;
    grouped?: any;
}

@withI18nHelper()
class PagerComponent extends React.Component<IPagerProps, any> {
    getPageLocation(page: number) {
        // const router = this.context.router;
        const location = assign({}, this.props.location);
        const oldsearch = parse(location.search);
        const newsearch = { page: page };
        location.search = stringify(assign({}, oldsearch, newsearch));
        return location;
    }

    setPage(arg, page: number) {
        arg.preventDefault();
        arg.stopPropagation();
        this.props.swipedTo(page);
    }

    formatPager(p: Array<any>, activeIndex: number) {
        let pages = p;
        if (pages.length > 9) {
            const allPages = pages;
            pages = [];
            if (activeIndex > 3) {
                pages.push(allPages[0]);
                pages.push(<div key="dash-0" className="pages-dash">...</div>);
            }
            for (let i = activeIndex - 2; i < activeIndex + 2; i++) {
                if (i >= 0 && i < allPages.length) pages.push(allPages[i]);
            }
            if (activeIndex < allPages.length - 3) {
                pages.push(<div key="dash-1" className="pages-dash">...</div>);
                pages.push(allPages[allPages.length - 1]);
            }
        }
        return pages;
    }

    getLabel = (label) => {
        if (typeof label === "string") {
            return <AppTextLabel i18n={label} />;
        } else {
            return <DynLabel component="span" i18n={label} />;
        }
    };

    render() {
        // logger.verbose("render AllEntities Pager");
        // let previous; let next; let pages; let counter; let
        //     loading; let pageSizePicker, counterLabel, pageSizeLabel;
        let previous; let next; let pages; let counterLabel; let counter; let loading;
        let pageSizePicker, pageSizeLabel;

        if (this.props.pageCount > 1) {
            if (this.props.currentPage == 0 && this.props.loading) {
                // null
            } else {
                if (this.props.currentPage > 0) {
                    previous = <Link
                        to={this.getPageLocation(this.props.currentPage)}
                        onClick={(arg) => this.setPage(arg, this.props.currentPage - 1)}
                        className="pagenav inwink-chevron-left previous bloc-lighttext clickable"
                    />;
                } else {
                    previous = <div className="pagenav inwink-chevron-left previous disabled" />;
                }

                if (this.props.currentPage + 1 < this.props.pageCount) {
                    next = <Link
                        to={this.getPageLocation(this.props.currentPage + 2)}
                        onClick={(arg) => this.setPage(arg, this.props.currentPage + 1)}
                        className="pagenav inwink-chevron-right next bloc-lighttext clickable"
                    />;
                } else {
                    next = <div className="pagenav inwink-chevron-right next disabled" />;
                }

                let activeIndex = 0;
                pages = range(this.props.pageCount).map((idx) => {
                    const isCurrent = idx === this.props.currentPage;
                    if (isCurrent) {
                        activeIndex = idx;
                        return <div key={"page" + idx} className="pagenav page selected bloc-accentbg">{(idx + 1)}</div>;
                    }
                    return <Link
                        to={this.getPageLocation(idx + 1)}
                        key={"page" + idx}
                        onClick={(arg) => this.setPage(arg, idx)}
                        className="pagenav page bloc-lightborder bloc-lighttext clickable"
                    >{(idx + 1)}</Link>;
                });

                pages = this.formatPager(pages, activeIndex);


                if (this.props.pageSizeLabel) {
                    pageSizeLabel = this.getLabel(this.props.pageSizeLabel);
                }
            }
        }

        if (!this.props.hideCounter && !this.props.grouped) {
            if (this.props.counterLabel) {
                counterLabel = this.getLabel(this.props.counterLabel);
            }
            if (this.props.loading) {
                loading = <Loader display="ring" hideContent />;
            }
            counter = (
                <div className="nbItems">
                    {loading}
                    {this.props.totalCount + " "}
                    {counterLabel}
                </div>
            );
        }

        if (this.props.pageSizeChanged) {
            pageSizePicker = (
                <React.Fragment>
                    <div className="pagesize-container">
                        <select
                            id="pagesize-select"
                            className="itemsperpage bloc-lightborder"
                            value={this.props.pageSize}
                            onChange={(arg) => { this.props.pageSizeChanged(+arg.target.value); }}
                        >
                            <option value={20}>20</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                        </select>
                        <label htmlFor="pagesize-select">
                            {pageSizeLabel}
                        </label>
                    </div>
                </React.Fragment>
            );
        }

        const pageSwiper = <React.Fragment>
            <div className="paging-container">
                {counter}
                <div className="paging bloc-lighttext">
                    {previous}
                    <div className="pages">
                        <div className="pages-items">
                            {pages}
                        </div>
                    </div>
                    {next}
                </div>
            </div>
        </React.Fragment>;

        return <div className="pager">
            {pageSwiper}
            {pageSizePicker}
        </div>;
    }
}

export const Pager: new (any) => React.Component<IPagerProps, any> = withRouter(PagerComponent as any) as any;

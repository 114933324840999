import * as React from 'react';
import * as isEmpty from 'lodash/isEmpty';
import { templatedString } from '@inwink/utils/methods/templatedString';
import { getBlocTemplate } from '@@services/itemshelpers';
import type { VisualTheme } from '@inwink/entities/visualtheme';
import type { States } from '@@services/services';
import type { IDynamicBlocWithProps } from '@@pages/common';
import { TeaserItems } from '@@pages/teaseritems';
import { eventsQuery } from '@@community/api/events';
import type { ITeaserItemsBlocCommonProperties } from '@@pages/teaseritems.props';
import type { ICommunityEvent } from '@@community/data';

export interface IBlocCommunityEventsProps
    extends IDynamicBlocWithProps<IBlocCommunityEventsBlocState, IBlocCommunityEventsBlocProperties> {
}

export interface IBlocCommunityEventsBlocState {
    events: ICommunityEvent[];
    itemtemplate: VisualTheme.IItemTemplate;
    loading: boolean;
    fromServer?: boolean;
}

export interface IBlocCommunityEventsBlocProperties extends ITeaserItemsBlocCommonProperties {
    targetPage: any;
}

export function blocEventsData(
    page: States.ICurrentPageState,
    statedata: IBlocCommunityEventsBlocState,
    blc: VisualTheme.IBlocContentTemplateBase,
    state: States.IAppState,
    dispatch: any,
    isRefresh: boolean
) {
    const blocdata = statedata;
    const bloc = blc;
    const itemtemplate = getBlocTemplate(state.community.data, bloc);
    blocdata.itemtemplate = itemtemplate;
    if (!blocdata.fromServer && !isRefresh) {
        blocdata.loading = true;
        blocdata.events = null;
    }

    if (__SERVERSIDE__) {
        blocdata.fromServer = true;
        return loadEvents(state.community, blocdata, blc).then((newdata) => {
            Object.assign(blocdata, newdata);
        });
    }
}

function loadEvents(
    community: States.ICommunityState,
    blocState: IBlocCommunityEventsBlocState,
    blc: VisualTheme.IBlocContentTemplateBase
) {
    const nbItems = blc?.properties?.maxItems || 6;
    return eventsQuery(
        community.requestManagers.apiFront,
        community.tenantid,
        {
            selects: {
                $all: true
            },
            filters: {
                startDate: {
                    $gt: new Date().toISOString()
                }
            },
            orders: [
                {
                    desc: false,
                    value: { startDate: {}}
                }
            ],
            page: {
                index: 0,
                size: nbItems
            }
        }
    ).then((res: any) => {
        const newBlocState = {...blocState};
        newBlocState.events = res.data;
        newBlocState.loading = false;
        return newBlocState;
    }).then(null, (err) => {
        console.error("error getting topics", err);
    });
}

export class BlocCommunityEvents extends React.PureComponent<IBlocCommunityEventsProps, any> {
    componentDidMount() {
        const blocState = {} as IBlocCommunityEventsBlocState;
        if (!this.props.blocState?.fromServer /* || this.props.user.currentUser?.member */) {
            blocState.loading = true;
            loadEvents(this.props.community, this.props.blocState, this.props.template).then((newBlocState) => {
                this.props.update(newBlocState);
            });
        }
        if (this.props.blocState?.fromServer) {
            blocState.fromServer = false;
        }

        if (!this.props.blocState?.itemtemplate) {
            blocState.itemtemplate = getBlocTemplate(this.props.community.data, this.props.template);
        }

        if (!isEmpty(blocState)) {
            this.props.update(blocState);
        }
    }

    getEventUrl = (content) => {
        if (this.props.template.properties && this.props.template.properties.targetPage) {
            return this.props.urlservice.pageUrl(templatedString(this.props.template.properties.targetPage,
                { ...this.props.datacontext, entity: content }));
        }

        if (content.computedUrl) {
            return {
                url: content.computedUrl,
                target: "_blank"
            };
        }

        return this.props.urlservice.pageUrl(`events/${content.id}`);
    };

    render() {
        if (!this.props.blocState) {
            return null;
        }

        return <TeaserItems
            {...this.props}
            className="teaser-events"
            animateItems={true}
            defaultItemsLayout="col3"
            loading={this.props.blocState.loading}
            items={this.props.blocState.events}
            linkto={!this.props.template.properties?.disableNavigation ? this.getEventUrl : null}
            allowEmpty={this.props.template.properties?.allowEmpty}
            itemtemplate={this.props.blocState.itemtemplate}
        />;
    }
}

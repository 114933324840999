import * as React from 'react';
import * as moment from 'moment';
import type { VisualTheme } from '@inwink/entities/visualtheme';
import type { IDynamicBlocWithProps } from '@@pages/common';
import { DynamicBloc } from '@@pages/common';
import type { ITeaserItemsBlocCommonProperties } from '@@pages/teaseritems.props';
import { ICommunityContentType } from '@@modules/community/data';
import { Kpi, IKpiProps } from './kpi';

import "./userkpis.less";

export interface IUserKpiDefinition {
    type: string;
    className: string;
    label: VisualTheme.IAppLabel;
    style: any;
    icon?: {
        className?: string;
    }
}

export interface IUserKpi extends IUserKpiDefinition {
    callback: IKpiCallback;
}

export interface IBlocCommunityUserKpisProps
    extends IDynamicBlocWithProps<IBlocCommunityUserKpisBlocState, IBlocCommunityUserKpisBlocProperties> {
}

export interface IBlocCommunityUserKpisBlocState {
}

export interface IBlocCommunityUserKpisBlocProperties extends ITeaserItemsBlocCommonProperties {
    kpis: IUserKpiDefinition[];
}

export interface IBlocCommunityUserKpisState {
    kpis: IUserKpi[];
}

export class BlocCommunityUserKpis extends React.PureComponent<IBlocCommunityUserKpisProps, IBlocCommunityUserKpisState> {
    constructor(props: IBlocCommunityUserKpisProps) {
        super(props);
        const kpis: IUserKpi[] = [];

        props.template.properties.kpis.forEach((k) => {
            const callback = loaders[k.type];
            if (callback) {
                kpis.push(Object.assign({}, k, {
                    callback: callback
                }));
            }
        });

        this.state = {
            kpis
        };
    }

    componentDidMount() {
    }

    render() {
        if (this.props.user.currentUser?.member?.lastVisit && !this.props.user.currentUser?.member?.$lastVisit) {
            this.props.user.currentUser.member.$lastVisit = moment(this.props.user.currentUser.member.lastVisit);
        }

        if (!this.props.user.currentUser?.member?.$lastVisit) {
            return null;
        }

        return <DynamicBloc {...this.props}>
            <div className="bloc-content">
                {this.state?.kpis?.map((k) => {
                    return <Kpi
                        key={k.type}
                        community={this.props.community}
                        user={this.props.user}
                        kpi={k}
                    />;
                })}
            </div>
        </DynamicBloc>;
    }
}

function contentCount(p: IKpiProps, contentType: ICommunityContentType) {
    return import("@@community/api/contents").then((mod) => {
        return mod.contentCount(p.community.requestManagers.apiFront, contentType, {
            filters: {
                createdAt: {
                    $gt: p.user.currentUser.member.$lastVisit.toISOString()
                }
            }
        });
    });
}

declare type IKpiCallback = (props: IKpiProps) => Promise<number>;
const loaders: Record<string, IKpiCallback> = {
    unreadmessages: (p) => {
        return import("@@community/api/discussions").then((mod) => {
            return mod.unread(p.community.requestManagers.apiFront);
        });
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    newpublications: (p) => {
        return Promise.resolve(42);
    },
    newarticles: (p) => {
        return contentCount(p, "Article");
    },
    newvideos: (p) => {
        return contentCount(p, "Vod");
    },
    newdocuments: (p) => {
        return contentCount(p, "DocumentCommunity");
    },
    newevents: (p) => {
        return contentCount(p, "IWEvent");
    },
    comingevents: (p) => {
        return import("@@community/api/events").then((mod) => {
            return mod.eventsCount(p.community.requestManagers.apiFront, p.community.tenantid, {
                filters: {
                    startDate: {
                        $gt: p.user.currentUser.member.$lastVisit.toISOString()
                    }
                }
            });
        });
    },
    newmembers: (p) => {
        return import("@@community/api/members").then((mod) => {
            return mod.membersCount(p.community.requestManagers.apiFront, {
                filters: {
                    createdAt: {
                        $gt: p.user.currentUser.member.$lastVisit.toISOString()
                    }
                }
            });
        });
    },
    allmembers: (p) => {
        return import("@@community/api/members").then((mod) => {
            return mod.membersCount(p.community.requestManagers.apiFront, {});
        });
    }
};

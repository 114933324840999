/* eslint-disable max-classes-per-file */
import * as React from 'react';
import type { IMember } from '@@community/data';
import { IMemberInfoContext, MemberInfoContext } from '../memberinfocontext';
import { MemberBubble } from '../memberbubble';
import { MemberItem } from '../memberitem';

export interface IMemberInfoProps {
    memberid?: string;
    member?: IMember;
    children?: React.ReactNode;
}

export class MemberInfo extends React.PureComponent<IMemberInfoProps, any> {
    render() {
        return <MemberInfoContext.Consumer>
            {(ctx) => <MemberInfoContent {...this.props} memberContext={ctx}>{this.props.children}</MemberInfoContent>}
        </MemberInfoContext.Consumer>;
    }
}

interface IMemberInfoContentProps extends IMemberInfoProps {
    memberContext: IMemberInfoContext;
    children?: React.ReactNode;
}

class MemberInfoContent extends React.Component<IMemberInfoContentProps, any> {
    unmounted = false;

    componentWillUnmount() {
        this.unmounted = true;
    }

    componentDidMount() {
        if (!this.props.member && this.props.memberid && this.props.memberContext && !this.unmounted) {
            this.props.memberContext.getMemberInfo(this.props.memberid).then((res) => {
                if (!this.unmounted) {
                    this.setState({ memberInfo: res });
                }
            });
        }
    }

    render() {
        const member = this.props.member || this.state?.memberInfo;

        return React.cloneElement(this.props.children as any, { member: member });
    }
}

export interface IMemberInfoBubbleProps extends IMemberInfoProps {
    className?: string;
}

// eslint-disable-next-line prefer-arrow-callback
export const MemberInfoBubble = React.memo(function MemberInfoBubble(props: IMemberInfoBubbleProps) {
    return <MemberInfo memberid={props.memberid} member={props.member}>
        <MemberBubble className={props.className} />
    </MemberInfo>;
});

export interface IMemberInfoItemProps extends IMemberInfoProps {
    bubbleClassName?: string;
}
// eslint-disable-next-line prefer-arrow-callback
export const MemberInfoItem = React.memo(function MemberInfoItem(props: IMemberInfoItemProps) {
    return <MemberInfo memberid={props.memberid} member={props.member}>
        <MemberItem bubbleClassName={props.bubbleClassName} />
    </MemberInfo>;
});

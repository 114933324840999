import * as React from 'react';
import type { ItemTemplateProps } from '@@components/templates/itemtemplate.props';
import { ItemTemplate } from '@@components/templates/itemtemplate';
import { withItemTemplateProvider, IItemTemplateProvider } from '@@components/templates/itemtemplateprovider';

export interface ICompanyItemProps extends ItemTemplateProps {
    itemtemplateprovider?: IItemTemplateProvider,
    data: {
        companyId?: string;
    };
    theme: any;
}

@withItemTemplateProvider()
export class CompanyItem extends React.PureComponent<ICompanyItemProps, any> {
    render() {
        if (this.props.datacontext && this.props.data?.companyId) {
            const templateprovider = this.props.itemtemplateprovider;
            const companyId = this.props.data.companyId;

            if (templateprovider && companyId) {
                const childtemplate = templateprovider.getTemplate("companytemplate");

                if (childtemplate) {
                    return <div className="company">
                        <ItemTemplate
                            key={companyId}
                            page={this.props.page}
                            user={this.props.user}
                            urlservice={this.props.urlservice}
                            datacontext={this.props.datacontext}
                            linkto={this.props.urlservice.pageUrl(`companies/${companyId}`)}
                            template={childtemplate}
                            className="companyitem"
                            theme={this.props.theme}
                            data={this.props.data}
                        />
                    </div>;
                }
            }
        }
        return null;
    }
}

import { ITeaserItemsBlocCommonProperties } from "@@components/dynamicpage/teaseritems.props";
import { States } from "@@services/services";
import { translateBag } from "@inwink/i18n";

export function getEmptyMessage(i18n:States.i18nState, properties: ITeaserItemsBlocCommonProperties) {
    const emptyMessage = "community.content.noitem";
    
    const customMessage = properties?.emptyMessage 
        ? translateBag(i18n, properties.emptyMessage)
        : emptyMessage;

    return customMessage;
}
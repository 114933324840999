import * as React from 'react';
import { AsyncButton } from '@inwink/buttons/asyncbutton';
import { IEntityDetailActionProps } from '@@pages/entitydetail/entitydetail.actions';
import { registerIWEvent } from '@@community/api/contents';
import { hasValidMembershipLevel, type ICommunityIWEvent } from '@@community/data';
import { AppTextLabel } from '@inwink/i18n';
import { actions as pageActions } from '@@services/pageservice';
import { connectwith } from '@inwink/react-utils/decorators/connectwith';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { userMessageActions } from '@@services/usermessageactions';
import { userMessageModule } from '@@routes/appmodules';
import { getRegisterIWEventMode, IRegisterIWEventModalProps, RegisterIWEventModal } from './registeriweventmodal';
import { registerEvent } from '@@modules/community/api/events';
import { debounce } from '@inwink/react-utils/decorators/debounce';
import { States } from '@@services/services';
import { IInwinkStore } from '@@store/index';

import './registeriwevent.less';

interface IRegisterIWEventProps extends IEntityDetailActionProps {
    userMessageActions?: typeof userMessageActions;
}

interface IRegistrationIWEventEntity extends ICommunityIWEvent {
    anonymousRegistrationPerformed?: boolean;
}

@connectwith(() => {
    return {
    };
}, (dispatch) => {
    return {
        pageActions: bindActionCreators(pageActions, dispatch),
        userMessageActions: bindActionCreators(userMessageActions, dispatch)
    };
})

export class RegisterIWEvent extends React.PureComponent<IRegisterIWEventProps, any> {
    checkDates(iwevent: ICommunityIWEvent) {
        if (iwevent) {
            if (iwevent.startDate && !iwevent.$startDate) {
                iwevent.$startDate = moment(iwevent.startDate);
            }
            if (iwevent.endDate && !iwevent.$endDate) {
                iwevent.$endDate = moment(iwevent.endDate);
            }
        }
    }

    registerToInwinkEvent = (arg: React.MouseEvent<any>) => {
        const content: ICommunityIWEvent = this.props.datacontext.entity;

        if (!content?.isRegistered) {
            if (content?.inwinkEventRegistrationMode === "Direct") {
                return import("@@community/features/user/useractions").then((mod) => {
                    const targ = arg.target as HTMLElement;
                    return mod.userActions.promptUserAccess(this.props.i18nHelper,
                        targ)(this.props.store.dispatch, this.props.store.getState)
                        .then((canAct) => {
                            if (canAct) {
                                return new Promise((resolve, reject) => {
                                    registerIWEvent(
                                        this.props.community.requestManagers.apiFront,
                                        content.inwinkEventId,
                                        this.props.community.tenantid
                                    ).then(() => {
                                        markIWEventInwinkSetIsRegistered(this.props.user, content,
                                            this.props.store, true, this.props.pageActions);
                                    }, (err) => {
                                        console.error("error removing register iwevent", err);
                                        inwink.toast.add(
                                            {
                                                title: this.props.i18nHelper.translate(
                                                    "actions.content.iwevent.registermember.error")
                                            },
                                            "error");
                                        reject(err);
                                    });
                                });
                            }
                        });
                });
            } else {
                if (content?.inwinkEventUrl) {
                    let url;

                    if (this.props.user.currentUser.detail) {
                        const { firstname, lastname, email, company, jobTitle } = this.props.user.currentUser.detail;
                        const params = [
                            `firstname=${firstname || ''}`,
                            `lastname=${lastname || ''}`,
                            `email=${email || ''}`,
                            `company=${company || ''}`,
                            `jobTitle=${jobTitle || ''}`,
                        ];
                        url = `${content.inwinkEventUrl}/registration/register?${params.join('&')}`;
                    } else {
                        url = `${content.inwinkEventUrl}/registration/register`;
                    }

                    window.open(url, '_blank');
                }
            }
        }
    };

    registerToEvent = (arg: React.MouseEvent<any>) => {
        const content: ICommunityIWEvent = this.props.datacontext.entity;
        if (!content?.isRegistered) {
            const title = this.props.i18nHelper?.translateBag(this.props.action?.label)
                || "community.content.actions.registeriwevent";
            const targ = arg.target as HTMLElement;
            return new Promise((resolve, reject) => {
                const prompModal: (anonymous: boolean, email?: string) => Promise<void> = (anonymous,
                    email) => {
                    return (this.props.userMessageActions.showModal(this.props.i18nHelper
                        , RegisterIWEventModal,
                        title, {
                            community: this.props.community,
                            i18nHelper: this.props.i18nHelper,
                            iwEvent: content,
                            withCreateMemberStep: anonymous,
                            userMessageActions: this.props.userMessageActions,
                            title,
                            user: this.props.user,
                            email: email
                        } as IRegisterIWEventModalProps, "") as any)
                        .then((res) => {
                            if (res) {
                                markIWEventInwinkSetIsRegistered(this.props.user, content,
                                    this.props.store, true, this.props.pageActions, anonymous);
                            } else if (res == false) {
                                inwink.toast.add(
                                    {
                                        title: this.props.i18nHelper.translate(
                                            "actions.content.iwevent.registermember.error")
                                    },
                                    "error");
                                reject("error");
                            } else {
                                resolve();
                            }
                        });
                };

                // Les différents cas ici vont de pair avec les conditions d'affichages du bouton.
                if (!this.props.user.currentUser) {
                    let next: () => Promise<void>;
                    userMessageModule().then((usermessage) => {
                        const mode = getRegisterIWEventMode(this.props.community, content, false);
                        // L'event n'autorise pas les nons membres à s'inscrire
                        const disableRegistration = mode == "forbid";
                        usermessage.loginActions.notifyRequireLogin(
                            targ,
                            this.props.i18nHelper,
                            {
                                disableRegistrationAction: disableRegistration,
                                tradsOptions: {
                                    errorNoAccount: "actions.content.iwevent.registermember.error.noaccount"
                                }
                            }, (action, email) => {
                                if (!disableRegistration && action == "registration") {
                                    // On gére notre propre flow d'inscription
                                    next = () => {
                                        if (mode != "prompt") throw new Error("invalid case");
                                        return prompModal(true, email);
                                    };
                                    return Promise.resolve(false);
                                } else if (action == "login") {
                                    // On ne fait rien, on va être rediriger vers l'auth
                                }
                                return Promise.resolve(true);
                            }
                        )(this.props.store.dispatch, this.props.store.getState).then(() => {
                            if (next) {
                                next();
                            } else {
                                resolve();
                            }
                        });
                    });
                } else if (!this.props.user.currentUser.member?.id) {
                    // L'utilisateur n'est pas membre
                    // on affiche le formulaire d'inscription membre avec le potentiel form
                    prompModal(true);
                } else {
                    // L'utilisateur est membre
                    const { hasMembership, membershipIsValid } = hasValidMembershipLevel(this.props.user.currentUser.member);
                    const mode = getRegisterIWEventMode(this.props.community, content, true, hasMembership && membershipIsValid);
                    if (mode == "direct") {
                        registerEvent(
                            this.props.community.requestManagers.apiFront,
                            content.id,
                            {}
                        ).then(() => {
                            markIWEventInwinkSetIsRegistered(this.props.user, content,
                                this.props.store, true, this.props.pageActions);
                        }, (err) => {
                            console.error("error removing register iwevent", err);
                            inwink.toast.add(
                                {
                                    title: this.props.i18nHelper.translate(
                                        "actions.content.iwevent.registermember.error")
                                },
                                "error");
                            reject(err);
                        });
                    } else if (mode == "prompt") {
                        prompModal(false);
                    } else {
                        throw new Error("invalid case");
                    }
                }
            });
        }
    };

    componentDidUpdate(prevProps: IRegisterIWEventProps) {
        const oldContent: IRegistrationIWEventEntity = prevProps.datacontext.entity;
        const newContent: IRegistrationIWEventEntity = this.props.datacontext.entity;
        if (oldContent && newContent && oldContent.isRegistered != newContent.isRegistered) {
            if ((oldContent.isRegistered == false || newContent.anonymousRegistrationPerformed) && newContent.isRegistered) {
                this.promptSuccess();
            }
        }
    }

    @debounce(500)
    promptSuccess() {
        inwink.toast.add(
            { title: this.props.i18nHelper.translate("actions.content.iwevent.registermember") },
            "success");
    }

    onClick = (arg: React.MouseEvent<any>) => {
        arg.preventDefault();
        if (this.inwinkRegistration()) {
            return this.registerToInwinkEvent(arg);
        } else if (this.registration()) {
            return this.registerToEvent(arg);
        }
    };

    registration = () => {
        const iwevent = (this.props.datacontext?.entity as ICommunityIWEvent);
        const registration = iwevent?.eventType == "SmallEvent"
            || iwevent?.eventType == "Webinar";
        return registration;
    };

    inwinkRegistration = () => {
        const iwevent = (this.props.datacontext?.entity as ICommunityIWEvent);
        const inwinkRegistration = iwevent?.eventType == "InwinkEvent"
            && (iwevent?.inwinkEventRegistrationMode == "Direct"
                || (iwevent?.inwinkEventRegistrationMode == "EventWebsite" && iwevent?.inwinkEventUrl));
        return inwinkRegistration;
    };

    render() {
        if (!this.props.user?.checked) return;
        if (!this.inwinkRegistration() && !this.registration()) {
            return null;
        }
        const iwevent = (this.props.datacontext?.entity as IRegistrationIWEventEntity);
        this.checkDates(iwevent);

        if (iwevent?.anonymousRegistrationPerformed || (!iwevent?.isRegistered
            && iwevent?.$endDate?.isAfter?.(moment()))) {
            if (this.props.user?.currentUser?.member
                && iwevent?.isRegistered != true && iwevent?.isRegistered != false) {
                // le user est co, on a pas encore récupéré son inscription
                return null;
            }

            if (this.registration() && this.props.user?.currentUser) {
                const isMember = this.props.user?.currentUser?.member?.id ? true : false;
                const { hasMembership, membershipIsValid } = hasValidMembershipLevel(this.props.user.currentUser.member);
                const mode = getRegisterIWEventMode(this.props.community, iwevent,
                    isMember, isMember && hasMembership && membershipIsValid);
                if (mode == "forbid" || mode == "invalid") {
                    // L'event n'autorise pas les non membres à s'y inscrire
                    return null;
                }
            }

            const classNames = ["registeriwevent"];

            if (this.props.action?.displayOptions?.isSecondary) {
                classNames.push('lightbtn');
            }

            const readonly = iwevent?.anonymousRegistrationPerformed == true;

            return <AsyncButton key={"regiseriwevent_" + iwevent?.isRegistered}
                className={classNames.join(' ')} disabled={readonly}
                onClick={readonly ? null : this.onClick}>
                {this.props.action?.icon?.className
                    ? <i className={this.props.action.icon.className} />
                    : null}
                <AppTextLabel i18n={
                    this.props.i18nHelper?.translateBag(this.props.action?.label)
                    || "community.content.actions.registeriwevent"} />
            </AsyncButton>;
        }

        return null;
    }
}

export function markIWEventInwinkSetIsRegistered(user: States.IAppUserState, content: ICommunityIWEvent,
    store: IInwinkStore, isRegistered: boolean, pageactions: typeof pageActions, anonymous = false) {
    if (content.eventType == "InwinkEvent"
        && content.inwinkEventRegistrationMode == "Direct"
        && user?.currentUser) {
        const newmember = Object.assign({}, user.currentUser.member);
        const contactEvents = newmember.inwinkcustomercontact.contactEvents ?? [];
        let contactEvent = contactEvents.filter(ce => ce.eventId == content.inwinkEventId)[0];
        if (contactEvent == null) {
            contactEvent = { eventId: content.inwinkEventId, isRegistered: isRegistered };
            contactEvents.push(contactEvent);
        }
        contactEvent.isRegistered = isRegistered;
        newmember.inwinkcustomercontact = Object.assign({}, newmember.inwinkcustomercontact, {
            contactEvents: contactEvents
        });
        store.dispatch({
            type: "USER_MEMBER_CHANGED",
            payload: newmember
        });
    }
    pageactions.patchCurrentPageEntity({ isRegistered: isRegistered, anonymousRegistrationPerformed: anonymous });
}
import * as React from 'react';
import { AppTextLabel } from '@inwink/i18n';
import { AsyncButton } from '@inwink/buttons/asyncbutton';
import type { ItemTemplateProps } from '@@components/templates/itemtemplate.props';
import { Entities } from '@inwink/entities/entities';

interface IRenderActionProps {
    i18nHelper: Entities.i18nHelper;
    className: string;
    titleOn?: string;
    titleOff?: string;
    iconOn?: string;
    iconOff?: string;
    display: IActionDisplay;
    isOn?: boolean;
    count?: number;
    callbackOn?: (arg: React.MouseEvent<any>) => void | Promise<any>;
    callbackOff?: (arg: React.MouseEvent<any>) => void | Promise<any>;
}

export interface IActionConf {
    showLabel: boolean;
    showIcon: boolean;
    inwinkIcon?: string;
    labels: any;
}

export interface IActionDisplay {
    conf: IActionConf;
    displayAsText: boolean;
    displayAsIcon: boolean;
    noDisplay: boolean;
    classes: string[];
}

export const RenderAction = React.memo(function renderAction(props: IRenderActionProps) {
    const isOn = props.isOn;
    let content = null;
    let icon = null;
    let label = null;
    const labelText = isOn ? props.titleOn : props.titleOff;

    if (props.display?.conf?.showIcon || props.display?.displayAsIcon) {
        let inwinkIcon = isOn ? props.iconOn : props.iconOff;
        if (props.display?.conf?.inwinkIcon) {
            inwinkIcon = props.display?.conf?.inwinkIcon;
        }

        icon = <i className={inwinkIcon} />;
    }

    if (props.display?.conf?.showLabel || props.display?.displayAsText) {
        label = <AppTextLabel i18n={labelText} />;
    }
    content = <span>{icon} {label}</span>;

    let className = "action " + (props.className || "") + (isOn ? " checked" : " isoff");
    if (props.display?.classes && props.display?.classes.length) {
        className = className + " " + props.display?.classes.join(" ");
    }

    return <AsyncButton
        className={className}
        title={props.i18nHelper.translate(labelText)}
        onClick={isOn ? props.callbackOn : props.callbackOff}
    >
        {content} {props.count !== undefined ? <span className="count">{props.count || "0"}</span> : null}
    </AsyncButton>;
});

export function getDisplayFor(props: ItemTemplateProps, propKey: string, templateOptionsKey: string) {
    const conf = props.template.properties && props.template.properties[propKey];
    const templateoptions = (props.datacontext as any)?.templateOptions;
    let options = templateoptions && templateoptions[templateOptionsKey];
    let displayAsText = false;
    let displayAsIcon = false;
    let noDisplay = false;
    const classes = [];

    if (options && options.length) {
        if (typeof options === 'string') {
            options = [options];
        }
        options.map((a) => {
            if (a === "icon") {
                displayAsIcon = true;
            } else if (a === "text") {
                displayAsText = true;
            }
            classes.push(a);
            return null;
        });
    } else {
        noDisplay = true;
    }

    return {
        conf,
        displayAsText,
        displayAsIcon,
        noDisplay,
        classes
    };
}
import * as React from 'react';
import { connectwith } from '@inwink/react-utils/decorators/connectwith';
import type { States } from '@@services/services';
import { getMemberInfo } from '@@community/api/memberinfo';
import type { IMember } from '@@community/data';
import { IMemberInfoContext, MemberInfoContext} from './memberinfocontext';

export interface IMemberInfoContextSourceProps {
    user?: States.IAppUserState;
    community?: States.ICommunityState;
    customcontext?: any;
    children?: React.ReactNode;
}

@connectwith((state) => {
    return {
        user: state.user,
        community: state.community
    };
})
export class MemberInfoContextSource extends React.Component<IMemberInfoContextSourceProps, any> {
    memberContext: IMemberInfoContext;

    memberPromises: Record<string, Promise<IMember>> = {};

    constructor(props) {
        super(props);

        this.memberContext = {
            getMemberInfo: (memberId: string) => {
                const existing = this.memberPromises[memberId];
                if (existing) {
                    return existing;
                }

                if (this.props.user?.currentUser?.member?.id === memberId) {
                    const promise = Promise.resolve(this.props.user?.currentUser?.member);
                    this.memberPromises[memberId] = promise;
                    return promise;
                }

                const promise = getMemberInfo(
                    this.props.community.requestManagers.apiFront,
                    this.props.community.communityid,
                    memberId,
                    this.props.customcontext?.forumId,
                    this.props.customcontext?.groupId
                ).then(null, (err) => {
                    console.error("error getting membe info", err);
                    if (this.memberPromises[memberId] === promise) {
                        delete this.memberPromises[memberId];
                    }
                }) as any;
                this.memberPromises[memberId] = promise;

                return promise;
            }
        };
    }

    render() {
        return <MemberInfoContext.Provider value={this.memberContext}>
            {this.props.children}
        </MemberInfoContext.Provider>;
    }
}

import * as React from 'react';
import type { IMember } from '@@community/data';
import { MemberBubble } from '../memberbubble';
import { AppTextLabel } from '@inwink/i18n';
import "./memberitem.less";

export interface IMemberItemProps {
    member?: IMember;
    className?: string;
    bubbleClassName?: string;
    disableClick?: boolean;
    loading?: boolean;
}

// eslint-disable-next-line prefer-arrow-callback
export const MemberItem = React.memo(function MemberItem(props: IMemberItemProps) {
    const m = props.member;

    if (!m) {
        const classes = ["member-bubble", "member-bubble-unknown", "bloc-lightborder", "bloc-accentbg", "inwink-account"];
        if (props.bubbleClassName) {
            classes.push(props.bubbleClassName);
        }
        return <div className="memberitem placeholder">
            <div className={"member-bubble member-bubble-unknown bloc-lightborder bloc-accentbg inwink-account " 
                + (props.bubbleClassName || "")} />
            <div className="detail">
                {props.loading ? null : <AppTextLabel className='member-unknown' i18n='community.member.unknown' />}
            </div>
        </div>;
    }

    const jttl = m?.jobTitle?.length >= 30;
    const ctl = m?.company?.length >= 30;

    return <div className="memberitem">
        <MemberBubble className={props.bubbleClassName} member={m} disableClick={props.disableClick} />
        <div className="detail">
            <div className="fullname">
                <span className="firstname">{(m?.firstname || "").trim()}</span>
                <span className="lastname">{(m?.lastname || "").trim()}</span>
            </div>
            {m?.jobTitle || m?.company ? (
                <div className={"information " + (jttl || ctl ? "information-long" : "")}>
                    {m?.jobTitle ? <span className={"jobtitle " + (jttl ? "jobtitle-long" : "")}>
                        {m?.jobTitle.trim()}</span> : ""}
                    {m?.company ? <span className={"company " + (ctl ? "company-long" : "")}>
                        {m?.company.trim()}</span> : ""}
                </div>
            ) : (
                ""
            )}
        </div>
    </div>;
});
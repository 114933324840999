import * as React from 'react';
import { NavLink } from 'react-router-dom';
import type { ItemTemplateProps } from '@@components/templates/itemtemplate.props';
import { ItemTemplateLogoPicture } from '@@components/templates/picturetemplates';
import { AppTextLabel } from '@inwink/i18n/apptextlabel';

import './sponsorcontent.less';

export interface ISponsorContentProps extends ItemTemplateProps {
}

export class SponsorContent extends React.PureComponent<ISponsorContentProps, any> {
    render() {
        if (!this.props.data?.companyId) {
            return null;
        }

        const company = this.props.data.company;

        return <NavLink
            key={company.id}
            className="partner-wrapper"
            to={this.props.urlservice.pageUrl("companies/" + company.id)}
        >
            <AppTextLabel i18n="company.sponsorby" className="partner-text" />
            <ItemTemplateLogoPicture
                {...this.props}
                useImgTag={true}
                hideTextIfLogo={true}
            />
        </NavLink>;
    }
}

import * as React from 'react';
import { registerEvent } from '@@modules/community/api/events';
import { CommunityOnlineForm, ICommunityOnlineProps } from '@@modules/community/components/onlineform';
import { States } from '@@services/services';
import { ICommunityIWEvent } from '@@modules/community/data';
import { Entities } from '@inwink/entities/entities';
import { userMessageActions } from '@@services/usermessageactions';
import { ModalContent, ModalWithFooter } from '@@components/modals';
import { Loader } from '@inwink/loader';
import { filterBlocs } from '@@services/pageservice';
import { blocMultistepRegistrationName } from '@@modules/community/features/module.registration';
import { BlocMultistepRegistration } from '@@modules/community/features/user/blocs/bloc.multistepregistration';
import { VisualTheme } from '@inwink/entities/visualtheme';

export interface IRegisterIWEventModalProps {
    community: States.ICommunityState;
    user: States.IAppUserState;
    iwEvent: ICommunityIWEvent;
    i18nHelper: Entities.i18nHelper;
    onCompleted?: (res?: any) => void;
    title?: string;
    userMessageActions?: typeof userMessageActions;
    withCreateMemberStep: boolean;
    email?: string;
}

interface IRegisterIWEventModalState {
    loading: boolean;
    step: 'createmember' | 'registrationform' | 'registration';
    registrationData: any;
    createMemberData: any;
    registrationPage: Entities.IContentTemplate;
    multistepRegistrationBloc: VisualTheme.IBlocContentTemplateBase;
}

export class RegisterIWEventModal extends React.PureComponent<IRegisterIWEventModalProps, IRegisterIWEventModalState> {
    constructor(props: IRegisterIWEventModalProps) {
        super(props);
        const step = this.props.withCreateMemberStep ? 'createmember' : 'registrationform';
        const regLink = this.props.community.detail.configuration?.content?.events?.nonMembersRegistrationLink
            ?? this.props.community.detail.configuration?.registration?.defaultRegistrationLink;
        let multistepRegistrationBloc;
        let registrationPage;
        if (step == 'createmember' && regLink?.tinyUrl) {
            registrationPage = this.props.community.data.templates.data.filter((t) => {
                return t.application == regLink.application
                    && t.contentType == regLink.contentType && t.tinyUrl == regLink.tinyUrl;
            })[0];
            if (registrationPage?.config?.blocs?.length > 0) {
                const blocs = registrationPage.config.blocs;
                // Get first MultistepRegistrationBloc
                const datacontext = this.getPageDataContext();
                blocs.filter((b, idx) => filterBlocs(null, this.props.community, datacontext, b, idx)).forEach((bloc) => {
                    if (!multistepRegistrationBloc && bloc.content) {
                        bloc.content.forEach((blocContent) => {
                            if (!multistepRegistrationBloc && blocContent.type === blocMultistepRegistrationName) {
                                multistepRegistrationBloc = Object.assign({}, blocContent, {
                                    header: null
                                } as VisualTheme.IBlocContentTemplateBase);
                            }
                        });
                    }
                });
            }
        }

        this.state = {
            loading: false,
            step: step,
            createMemberData: {},
            registrationData: {},
            registrationPage: registrationPage,
            multistepRegistrationBloc: multistepRegistrationBloc
        };
    }

    getPageDataContext = () => {
        return {
            i18nstate: this.props.i18nHelper.i18n,
            eventstate: null,
            communitystate: this.props.community,
            userstate: this.props.user,
            event: null,
            rootwebsite: null,
            rootwebsitestate: null,
            user: this.props.user?.currentUser?.detail || null,
            fieldtemplate: null,
        } as Entities.IPageDataContext;
    };

    // reg = (createMember: boolean) => {
    //     const createMemberPromise = createMember ? () => {
    //         const registrationPage = this.props.community.detail?.configuration?.registration?.defaultRegistrationLink;
    //         if (!registrationPage || !registrationPage.tinyUrl) return Promise.reject("invalid configuration");
    //         return Promise.resolve(null);
    //     } : () => Promise.resolve(null);

    //     const content = this.props.iwEvent;
    //     return createMemberPromise().then((memberInfo) => {
    //         const doRegisterEvent = (data: any) => {
    //             return registerEvent(
    //                 this.props.community.requestManagers.apiFront,
    //                 content.id,
    //                 data,
    //                 memberInfo?._dataInfo, memberInfo?._sign
    //             ).then(() => {
    //                 // this.props.pageActions.patchCurrentPageEntity({ isRegistered: true });
    //             }, (err) => {
    //                 console.error("error removing register iwevent", err);
    //                 inwink.toast.add(
    //                     {
    //                         title: this.props.i18nHelper.translate(
    //                             "actions.content.iwevent.registermember.error")
    //                     },
    //                     "error");
    //                 throw err;
    //             });
    //         };

    //         // on affiche juste le prompt potentiel du form
    //         if (content.template?.fields?.length > 0) {
    //             const label = this.props.title
    //                 || "community.content.actions.registeriwevent";
    //             // On a un formulaire d'inscription, on l'affiche

    //             return (this.props.userMessageActions.showModal(this.props.i18nHelper,
    //                 (_props) => React.createElement(CommunityOnlineForm, {
    //                     ..._props, onValidate: (data) => {
    //                         _props.onCompleted(data);
    //                     }
    //                 }),
    //                 label
    //                 , fProps, "iwevent-registration-modal", null, null, null, null) as any)
    //                 .then((data) => {
    //                     if (data) {
    //                         return doRegisterEvent(data);
    //                     } else {
    //                         return Promise.resolve();
    //                     }
    //                 });
    //         } else {
    //             return doRegisterEvent({});
    //         }
    //     });
    // };

    registerToIWEvent = () => {
        return registerEvent(
            this.props.community.requestManagers.apiFront,
            this.props.iwEvent.id,
            this.state.registrationData,
            this.state.createMemberData?._dataInfo, this.state.createMemberData?._sign
        ).then(() => {
            this.props.onCompleted(true);
        }, (err) => {
            console.error("error register iwevent", err);
            this.props.onCompleted(false);
        });
    };

    render() {
        let content = <Loader />;
        if (this.state.step == 'createmember' && this.state.multistepRegistrationBloc) {
            content = <BlocMultistepRegistration
                {...this.props}
                bloctemplate={this.state.multistepRegistrationBloc as any}
                blocState={{}}
                datacontext={this.getPageDataContext()}
                event={null}
                i18n={this.props.i18nHelper.i18n}
                location={null}
                page={{
                    template: this.state.registrationPage
                } as any}
                pageActions={null}
                rootwebsite={null}
                template={this.state.multistepRegistrationBloc}
                theme={null}
                update={() => { }}
                urlservice={null}
                visualstate={[]}
                initialData={{
                    registered: { email: this.props.email },
                    registeredPatch: { email: this.props.email }
                }}
                onRegisteredCreated={(member) => {
                    const hasRegistrationForm = this.props.iwEvent.template?.fields?.length > 0;
                    this.setState({
                        loading: hasRegistrationForm ? false : true,
                        createMemberData: member,
                        step: hasRegistrationForm ? "registrationform" : "registration"
                    }, () => {
                        if (!hasRegistrationForm) {
                            this.registerToIWEvent();
                        }
                    });
                }}
            />;
        } else if (this.state.step == 'registrationform') {
            const label = this.props.title
                || "community.content.actions.registeriwevent";
            const fProps = {
                ...this.props,
                i18n: this.props.i18nHelper.i18n,
                entityValue: {},
                entityName: "",
                template: this.props.iwEvent.template,
                formTemplate: this.props.iwEvent.formTemplate || {},
                onValidate: (registrationData) => {
                    this.setState({
                        loading: true,
                        registrationData: registrationData,
                        step: "registration"
                    }, () => {
                        this.registerToIWEvent();
                    });
                    return Promise.resolve();
                },
                customActionName: label
            } as ICommunityOnlineProps;
            content = <CommunityOnlineForm {...fProps} />;
        }

        return <ModalWithFooter>
            <ModalContent>
                {content}
            </ModalContent>
        </ModalWithFooter>;
    }
}

export type RegisterIWEventMode = 'invalid' | 'forbid' | 'direct' | 'prompt';
export function getRegisterIWEventMode(community: States.ICommunityState, iwEvent: ICommunityIWEvent,
    userIsMember: boolean, userIsValidMember = false): RegisterIWEventMode {
    if (iwEvent.eventType == "SmallEvent"
        || iwEvent.eventType == "Webinar") {
        if (userIsMember) {
            if (!userIsValidMember && !iwEvent.allowNonMemberToRegister) return 'forbid';
            return iwEvent.template?.fields?.length > 0 ? 'prompt' : 'direct';
        } else if (iwEvent.allowNonMemberToRegister) {
            return community.detail.configuration?.registration?.defaultRegistrationLink?.tinyUrl ? 'prompt' : 'forbid';
        } else {
            return 'forbid';
        }
    }

    return 'invalid';
}
import * as React from 'react';
import { connect } from 'react-redux';
import { AppTextLabel, withI18nHelper } from '@inwink/i18n';
import { AsyncButton } from '@inwink/buttons/asyncbutton';
import type { Entities } from '@inwink/entities/entities';
import type { ItemTemplateProps } from '@@components/templates/itemtemplate.props';
import type { States } from '@@services/services';
import { wrapReduxStore, IInwinkStore } from '@@store/index';
import { bookmark, removeBookmark } from '@@community/api/topic';

export interface ITopicActionsProps extends ItemTemplateProps {
    i18n?: States.i18nState;
    community?: States.ICommunityState;
    i18nHelper?: Entities.i18nHelper;
    store?: IInwinkStore;
}

interface IActionConf {
    showLabel: boolean;
    showIcon: boolean;
    inwinkIcon?: string;
    labels: any;
}

interface ITopicActionsState {
    bookmark: ITopicActionDisplay;
}

interface ITopicActionDisplay {
    conf: IActionConf;
    displayAsText: boolean;
    displayAsIcon: boolean;
    noDisplay: boolean;
    classes: string[];
}

@withI18nHelper()
class TopicActionsComponent extends React.Component<ITopicActionsProps, ITopicActionsState> {
    constructor(props: ITopicActionsProps) {
        super(props);

        this.state = {
            bookmark: getDisplayFor(props, "bookmark", "bookmarkActionDisplay")
        };
    }

    bookmark = (arg: React.MouseEvent<any>) => {
        arg.preventDefault();
        return import("@@community/features/user/useractions").then((mod) => {
            const targ = arg.target as HTMLElement;
            return mod.userActions.promptUserAccess(this.props.i18nHelper,
                targ)(this.props.store.dispatch, this.props.store.getState)
                .then((canAct) => {
                    if (canAct) {
                        return bookmark(
                            this.props.community.requestManagers.apiFront, this.props.data.id
                        ).then((res: any) => {
                            if (res?.bookmarked) {
                                this.props.data.isBookmarked = true;
                                this.props.onDataUpdate(this.props.data);
                            }
                        }, (err) => {
                            console.error("error adding bookmark", err);
                        });
                    }
                });
        });
    };

    unbookmark = (arg: React.MouseEvent<any>) => {
        arg.preventDefault();
        return removeBookmark(
            this.props.community.requestManagers.apiFront, this.props.data.id
        ).then((res: any) => {
            if (res?.bookmarked) {
                this.props.data.isBookmarked = false;
                this.props.onDataUpdate(this.props.data);
            }
        }, (err) => {
            console.error("error removing bookmark", err);
        });
    };

    render() {
        return <div className={"topicitemactions" + (this.props.template.className ? " " + this.props.template.className : "")}>
            <RenderAction
                i18nHelper={this.props.i18nHelper}
                className="act-bookmark"
                display={this.state.bookmark}
                isOn={this.props.data.isBookmarked}
                iconOn="inwink-star"
                titleOn="community.content.actions.unbookmark"
                iconOff="inwink-star-outline"
                titleOff="community.content.actions.bookmark"
                callbackOn={this.unbookmark}
                callbackOff={this.bookmark}
            />
        </div>;
    }
}

function mapStateToProps(state: States.IAppState) {
    return {
        community: state.community
    };
}

function mapDispatchToProps() {
    return {
    };
}

export const TopicActions: new (any)
=> React.Component<ITopicActionsProps, any> = connect(
    mapStateToProps,
    mapDispatchToProps
)(wrapReduxStore(TopicActionsComponent) as any) as any;

interface IRenderActionProps {
    i18nHelper: Entities.i18nHelper;
    className: string;
    titleOn: string;
    titleOff: string;
    iconOn: string;
    iconOff: string;
    display: ITopicActionDisplay;
    isOn: boolean;
    callbackOn: (arg:React.MouseEvent<any>) => void | Promise<any>;
    callbackOff: (arg:React.MouseEvent<any>) => void | Promise<any>;
}

// eslint-disable-next-line prefer-arrow-callback
const RenderAction = React.memo(function renderAction(props: IRenderActionProps) {
    const isOn = props.isOn;
    let content = null;
    let icon = null;
    let label = null;
    const labelText = isOn ? props.titleOn : props.titleOff;

    if (props.display.conf?.showIcon || props.display.displayAsIcon) {
        let inwinkIcon = isOn ? props.iconOn : props.iconOff;
        if (props.display?.conf?.inwinkIcon) {
            inwinkIcon = props.display?.conf?.inwinkIcon;
        }

        icon = <i className={inwinkIcon} />;
    }

    if (props.display.conf?.showLabel || props.display.displayAsText) {
        label = <AppTextLabel i18n={labelText} />;
    }
    content = <span>{icon} {label}</span>;

    let className = "action " + (props.className || "") + (isOn ? " checked" : " isoff");
    if (props.display.classes && props.display.classes.length) {
        className = className + " " + props.display.classes.join(" ");
    }

    return <AsyncButton
        className={className}
        title={props.i18nHelper.translate(labelText)}
        onClick={isOn ? props.callbackOn : props.callbackOff}
    >
        {content}
    </AsyncButton>;
});

function getDisplayFor(props: ItemTemplateProps, propKey: string, templateOptionsKey: string) {
    const conf = props.template.properties && props.template.properties[propKey];
    const templateoptions = (props.datacontext as any)?.templateOptions;
    let options = templateoptions && templateoptions[templateOptionsKey];
    let displayAsText = false;
    let displayAsIcon = false;
    let noDisplay = false;
    const classes = [];

    if (options && options.length) {
        if (typeof options === 'string') {
            options = [options];
        }
        options.map((a) => {
            if (a === "icon") {
                displayAsIcon = true;
            } else if (a === "text") {
                displayAsText = true;
            }
            classes.push(a);
            return null;
        });
    } else {
        noDisplay = true;
    }

    return {
        conf,
        displayAsText,
        displayAsIcon,
        noDisplay,
        classes
    };
}

import * as React from 'react';
import { IEntityDetailActionProps } from '@@pages/entitydetail/entitydetail.actions';
import type { ICommunityTimedContent } from '@@community/data';
import { downloadEventICS } from '@@modules/community/api/events';

interface IEventICSProps extends IEntityDetailActionProps {
}

export function EventICS(props: IEventICSProps) {
    const content: ICommunityTimedContent = props.datacontext.entity;

    if (!content || !content.startDate || !content.endDate) {
        return null;
    }

    const handleDownload = () => {
        downloadEventICS(
            props.community.requestManagers.apiFront,
            content.id,
            content.title[props.i18nHelper.i18n.currentLanguageCode]
        );
    };

    const classNames = ["calendar"];

    if (props.action?.displayOptions?.isSecondary) {
        classNames.push('lightbtn');
    }

    return <a
        role="button"
        target="_blank"
        rel="noreferrer"
        onClick={handleDownload}
        className={classNames?.join(' ')}
    >
        <span className="text">
            <i className={props.action.icon.className || "inwink-calendar"} />
            {props.action.label?.[props.i18nHelper.i18n.currentLanguageCode]}
        </span>
    </a>;
}

import * as React from 'react';
import * as isEmpty from 'lodash/isEmpty';
import { templatedString } from '@inwink/utils/methods/templatedString';
import { getBlocTemplate } from '@@services/itemshelpers';
import type { VisualTheme } from '@inwink/entities/visualtheme';
import type { States } from '@@services/services';
import type { IDynamicBlocWithProps } from '@@pages/common';
import { TeaserItems } from '@@pages/teaseritems';
import { topicQuery } from '@@community/api/topic';
import type { ITeaserItemsBlocCommonProperties } from '@@pages/teaseritems.props';
import type { ICommunityTopic } from '@@community/data';
import type { IInwinkEntityV3QueryOptions } from '@@api/front';
import { withI18nHelper } from '@inwink/i18n';
import type { Entities } from '@inwink/entities/entities';
import { getUrlSlug } from 'helpers';

export interface IBlocCommunityTopicProps
    extends IDynamicBlocWithProps<IBlocCommunityTopicBlocState, IBlocCommunityTopicBlocProperties> {
    i18nHelper?: Entities.i18nHelper;
}

export interface IBlocCommunityTopicBlocState {
    topics: ICommunityTopic[];
    itemtemplate: VisualTheme.IItemTemplate;
    loading: boolean;
    fromServer?: boolean;
}

export interface IBlocCommunityTopicBlocProperties extends ITeaserItemsBlocCommonProperties {
    targetPage: any;
}

export function blocTopicData(
    page: States.ICurrentPageState,
    statedata: IBlocCommunityTopicBlocState,
    blc: VisualTheme.IBlocContentTemplate<IBlocCommunityTopicBlocProperties>,
    state: States.IAppState,
    dispatch: any,
    isRefresh: boolean
) {
    const blocdata = statedata;
    const bloc = blc;
    const itemtemplate = getBlocTemplate(state.community.data, bloc);
    blocdata.itemtemplate = itemtemplate;
    if (!blocdata.fromServer && !isRefresh) {
        blocdata.loading = true;
        blocdata.topics = null;
    }

    if (__SERVERSIDE__) {
        blocdata.fromServer = true;
        return loadTopics(
            state.community,
            blocdata,
            bloc
        ).then((newblocdata) => {
            Object.assign(blocdata, newblocdata);
            return blocdata;
        });
    }
}

function loadTopics(
    community: States.ICommunityState,
    blocstate: IBlocCommunityTopicBlocState,
    template: VisualTheme.IBlocContentTemplate<IBlocCommunityTopicBlocProperties>,
) {
    const nbItems = template?.properties?.maxItems || 6;
    const expression: any = {};
    const query: IInwinkEntityV3QueryOptions = {
        selects: {
            $all: true
        },
        page: {
            size: nbItems,
            index: 0
        },
        order: [
            {
                by: (template?.properties?.sort as any)?.propName || "createdAt",
                desc: (template?.properties?.sort as any)?.order === "desc"
            }
        ],
        filters: expression,
        expression: template?.properties?.filterexpr
    };
    return topicQuery(
        community.requestManagers.apiFront,
        query
    ).then((res: any) => {
        const newBlocState = {...blocstate};
        newBlocState.topics = res.data;
        return newBlocState;
    }).then(null, (err) => {
        console.error("error getting topics", err);
    });
}
@withI18nHelper()
export class BlocCommunityTopic extends React.PureComponent<IBlocCommunityTopicProps, any> {
    componentDidMount() {
        const blocState = {} as IBlocCommunityTopicBlocState;
        if (!this.props.blocState?.fromServer /* || this.props.user.currentUser?.member */) {
            blocState.loading = true;
            loadTopics(this.props.community, this.props.blocState, this.props.template).then((blocstate) => {
                this.props.update(blocstate);
            });
        }
        if (this.props.blocState?.fromServer) {
            blocState.fromServer = false;
        }
        if (!this.props.blocState?.itemtemplate) {
            blocState.itemtemplate = getBlocTemplate(this.props.community.data, this.props.template);
        }

        if (!isEmpty(blocState)) {
            this.props.update(blocState);
        }
    }

    getTopicUrl = (content: ICommunityTopic) => {
        if (this.props.template.properties && this.props.template.properties.targetPage) {
            return this.props.urlservice.pageUrl(templatedString(this.props.template.properties.targetPage,
                { ...this.props.datacontext, entity: content }));
        }
        const topicName = this.props.i18nHelper?.translateBag(content.title);
        return this.props.urlservice.pageUrl(`topics/${content.id}/${getUrlSlug(topicName)}`);
    };

    render() {
        if (!this.props.blocState) {
            return null;
        }

        const itemsToShow = this.props.template?.properties?.maxItems ? this.props.template.properties.maxItems : 6;

        
        return <TeaserItems
            {...this.props}
            className="teaser-topics"
            animateItems={true}
            loading={this.props.blocState?.loading}
            defaultItemsLayout="col3"
            items={this.props.blocState?.topics}
            linkto={!this.props.template.properties?.disableNavigation ? this.getTopicUrl : null}
            allowEmpty={this.props.template.properties?.allowEmpty}
            itemtemplate={this.props.blocState?.itemtemplate}
            itemsPreviewCount={itemsToShow}
        />;
    }
}

import type { ICommunityRequestManager, IRequestManager } from '@@services/apiaccessprovider.definition';
import type { States } from '@@services/services';
import { defaultPostHeaderForJsonData } from '@@api/index';

export function getMemberInfo(
    requestManager: IRequestManager,
    communityId: string,
    memberId: string,
    forumId?: string,
    groupId?: string
): Promise<States.ICommunityDetail> {
    const baseUrl = `/community/${communityId}`;
    const endUrl = `member/${memberId}/info`;
    let url;
    if (forumId) {
        url = `${baseUrl}/forum/${forumId}/${endUrl}`;
    } else if (groupId) {
        url = `${baseUrl}/group/${groupId}/${endUrl}`;
    } else {
        url = `${baseUrl}/${endUrl}`;
    }
    return requestManager.getJson(url);
}


export function getMemberDetail(
    requestManager: IRequestManager,
    communityId: string,
    memberId: string
): Promise<States.ICommunityDetail> {
    return requestManager.getJson("/community/" + communityId + "/member/" + memberId + "/detail");
}

export function getTrustedInfo(
    requestManager: ICommunityRequestManager,
    queryString: string
): Promise<any> {
    const url = "community/" + requestManager.communityId + "/" + "member" + "/" + "trustedinfo" + queryString;
    return requestManager.getJson(url, defaultPostHeaderForJsonData);
}
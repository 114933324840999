/* eslint-disable max-classes-per-file */
import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connectwith } from '@inwink/react-utils/decorators/connectwith';
import { AsyncButton } from '@inwink/buttons/asyncbutton';
import { withI18nHelper } from '@inwink/i18n/reactcontext';
import { Entities } from '@inwink/entities/entities';
import type { IDynamicBlocWithProps } from '@@pages/common';
import { userMessageActions } from '@@services/usermessageactions';
import { actions as pageActions } from '@@services/pageservice';
import { AppTextLabel } from '@inwink/i18n';
import { IVisibility, VisibilityPicker } from '@@community/components/visibility/visibilitypicker';
import type { ICommunityContent } from '@@community/data';
import { update } from '@@community/api/contents';
import { ModeratorToolbar, IModeratorToolbarItem } from '@@community/components/moderatortoolbar';
import { IPopoverManager, WithPopoverManager } from '@inwink/modals';
import { loadFileAssets } from '@inwink/entityform/filehelpers';
import type { IUpdateContentInfoProps } from "../../components/modals/updatecontent";
import type { IPublicationStatusProps } from "../../components/modals/publicationstatus";
import type { ITopicsProps } from "../../components/modals/topics";
import type { IFroalaArticleEditorProps } from '../../components/modals/articles/editor.froala';

import "./contentadmin.less";

interface IContentAdminBlocState {

}

interface IContentAdminBlocProperties {
    theme: string;
}

export interface IContentAdminProps
    extends IDynamicBlocWithProps<IContentAdminBlocState, IContentAdminBlocProperties> {
    userMessageActions?: typeof userMessageActions;
    pageActions: typeof pageActions;
    i18nHelper: Entities.i18nHelper;
    popovermgr?: IPopoverManager;
}

export interface IContentAdminState
    extends IDynamicBlocWithProps<IContentAdminBlocState, IContentAdminBlocProperties> {
}

@connectwith(null, (dispatch) => {
    return {
        userMessageActions: bindActionCreators(userMessageActions, dispatch),
        pageActions: bindActionCreators(pageActions, dispatch)
    };
})
@withI18nHelper()
export class ContentAdmin extends React.PureComponent<IContentAdminProps, any> {
    render() {
        return <WithPopoverManager>
            {(popovermgr) => <ContentAdminComponent {...this.props} popovermgr={popovermgr} />}
        </WithPopoverManager>;
    }
}

export interface IModeratorHeaderState {
    left: IModeratorToolbarItem[];
    right: IModeratorToolbarItem[];
}

class ContentAdminComponent extends React.PureComponent<IContentAdminProps, IModeratorHeaderState> {
    constructor(props) {
        super(props);
        this.state = this.getActions();
    }

    componentDidUpdate(prevProps: Readonly<IContentAdminProps>) {
        if (prevProps.datacontext.entity !== this.props.datacontext.entity) {
            this.setState(this.getActions());
        }
    }

    getActions() {
        const content: ICommunityContent = this.props.datacontext.entity;
        let access = null;
        if (content?.memberAccess && content?.memberAccess.length) {
            access = {};
            content?.memberAccess?.forEach((l) => {
                access[l.id] = true;
            });
        }

        if (!content) {
            return {left: [], right: []};
        }
        // let topics = null;
        // if (content.topics && content.topics.length) {
        //     topics = <span>
        //         {content.topics.map((t) => this.props.i18nHelper.translateBag(t.title)).join(", ")}
        //     </span>;
        // } else {
        //     topics = <AppTextLabel i18n="community.content.topics.empty" />;
        // }

        const result: {left: IModeratorToolbarItem[], right: IModeratorToolbarItem[]} = {
            left: [
                {
                    id: "title",
                    component: () => {
                        return <div className="toolbar-title">
                            <AppTextLabel i18n="community.content.moderation" component="h4" />
                        </div>;
                    }
                }
                // {
                //     component: (props) => {
                //         return <VisibilityPicker
                //             allowPublic={content.allowPublicView}
                //             hideForUnallowed={content.hideForUnallowed}
                //             restricted={access}
                //             onChange={this.visibilityChanged}
                //         />;
                //     }
                // },
                // {
                //     component: (props) => <AsyncButton
                //         className="lightbtn statuspicker"
                //         onClick={this.updateStatus}
                //     >
                //         <span className="statuspicker-label">
                //             <AppTextLabel i18n={"community.content.status." + content.status?.toLowerCase()} />
                //             <i className="inwink-chevron-down" />
                //         </span>
                //     </AsyncButton>
                // },
                // {
                //     component: (props) => <AsyncButton
                //         className="lightbtn edittopics"
                //         onClick={this.editTopics}
                //     >
                //         <span className="statuspicker-label">
                //             <span>
                //                 {topics}
                //             </span>
                //             <i className="inwink-chevron-down" />
                //         </span>
                //     </AsyncButton>
                // }
            ],
            right: [
                // {
                //     i18n: "community.content.actions.newpublication",
                //     callback: this.publishContent
                // },
                {
                    id: "statuspicker",
                    component: () => <AsyncButton
                        className="lightbtn statuspicker"
                        onClick={this.updateStatus}
                    >
                        <span className="statuspicker-label">
                            <AppTextLabel i18n={"community.content.status." + content.status?.toLowerCase()} />
                            <i className="inwink-chevron-down" />
                        </span>
                    </AsyncButton>
                },
                {
                    id: "btnupdate",
                    i18n: "actions.update",
                    callback: this.updateContent
                }
            ]
        };

        if (!this.props.customcontext?.groupId) {
            result.right = [
                {
                    component: () => {
                        return <VisibilityPicker
                            allowPublic={content.allowPublicView}
                            hideForUnallowed={content.hideForUnallowed}
                            restricted={access}
                            onChange={this.visibilityChanged}
                        />;
                    }
                },
                ...result.right
            ];
        }

        if (this.props.datacontext.entityKind === "Article") {
            result.right.push({
                i18n: "community.content.actions.updatearticle",
                callback: this.updateArticle
            });
        }

        return result;
    }

    updateContent = (arg: React.MouseEvent<any>) => {
        arg.preventDefault();
        return import('../../components/modals/updatecontent').then((mod) => {
            const props: IUpdateContentInfoProps = {
                i18nHelper: this.props.i18nHelper,
                community: this.props.community,
                content: this.props.datacontext.entity,
                contentType: this.props.datacontext.entityKind,
                user: this.props.user
            };

            (this.props.userMessageActions.showModal(
                this.props.i18nHelper,
                mod.UpdateContentInfo,
                "community.content.update",
                props,
                "community-updatecontentmodal"
            ) as any).then((updated) => {
                if (updated) {
                    this.entityUpdated();
                }
            });
        });
    };

    updateArticle = (arg: React.MouseEvent<any>) => {
        arg.preventDefault();
        const target = arg.currentTarget;
        return import('../../components/modals/articles/editor.froala').then((mod) => {
            // important de loadFileAssets ici car sinon monaco prend le pas sur le loader et lève une erreur
            return loadFileAssets().then(() => {
                const props: IFroalaArticleEditorProps = {
                    i18nHelper: this.props.i18nHelper,
                    community: this.props.community,
                    content: this.props.datacontext.entity,
                    user: this.props.user,
                    visualConfiguration: this.props.visualConfiguration
                };

                (this.props.userMessageActions.showModal(
                    this.props.i18nHelper,
                    mod.FroalaArticleEditor,
                    this.props.i18nHelper.translateBag(this.props.datacontext.entity.title),
                    props,
                    "community-updatearticlemodal",
                    target,
                    null,
                    null,
                    { disableOverlayDismiss: true }
                ) as any).then((res) => {
                    if (res) {
                        this.entityUpdated();
                    }
                });
            });
        });
    };

    editTopics = (arg: React.MouseEvent<any>) => {
        arg.preventDefault();
        const target = arg.currentTarget;
        return import('../../components/modals/topics').then((mod) => {
            const props: ITopicsProps = {
                i18nHelper: this.props.i18nHelper,
                community: this.props.community,
                content: this.props.datacontext.entity,
                contentType: this.props.datacontext.entityKind,
                user: this.props.user
            };

            (this.props.userMessageActions.showMessage(
                this.props.i18nHelper,
                mod.Topics,
                props,
                target,
                null,
                null,
                "community-updatecontenttopicsmodal"
            ) as any).then((res) => {
                if (res) {
                    this.entityUpdated();
                }
            });
        });
    };

    updateStatus = (arg: React.MouseEvent<any>) => {
        arg.preventDefault();
        const target = arg.currentTarget;
        return import('../../components/modals/publicationstatus').then((mod) => {
            const props: IPublicationStatusProps = {
                userMessageActions: this.props.userMessageActions,
                i18nHelper: this.props.i18nHelper,
                datacontext: this.props.datacontext,
                community: this.props.community,
                urlservice: this.props.urlservice,
                content: this.props.datacontext.entity,
                contentType: this.props.datacontext.entityKind,
                user: this.props.user
            };

            (this.props.userMessageActions.showMessage(
                this.props.i18nHelper,
                mod.PublicationStatus,
                props,
                target,
                null,
                null,
                "community-updatecontentmodal"
            ) as any).then((res) => {
                if (res) {
                    this.entityUpdated();
                }
            });
        });
    };

    // publishContent = (arg: React.MouseEvent<any>) => {
    //     arg.preventDefault();
    //     return import("@@community/features/feed/blocs/mainfeed/mainbloc/publish/publishmodal").then((mod) => {
    //         const props: IBlocCommunityFeedPublishBaseProps = {
    //             contentId: this.props.datacontext.entity.id,
    //             contentType: this.props.datacontext.entityKind,
    //             community: this.props.community,
    //             user: this.props.user,
    //             allowPublic: true,
    //             restricted: {},
    //             configuration: {}
    //         };

    //         this.props.userMessageActions.showModal(
    //             this.props.i18nHelper,
    //             mod.PublishModal,
    //             "",
    //             props,
    //             "sendpublicationmodal"
    //         );
    //     });
    //     return Promise.resolve();
    // };

    visibilityChanged = (vis: IVisibility) => {
        console.log("visibility", vis);
        let access: any[] = null;
        if (vis.restricted) {
            access = [];
            Object.keys(vis.restricted).forEach((k) => access.push({ id: k }));
        }
        update(
            this.props.community.requestManagers.apiFront,
            this.props.datacontext.entityKind,
            this.props.datacontext.entity.id,
            {
                allowPublicView: vis.allowPublic,
                hideForUnallowed: vis.hideForUnallowed,
                memberAccess: access
            }
        ).then(() => {
            this.entityUpdated();
        }, (err) => {
            console.error("error updating visibility", err);
        });
    };

    entityUpdated = () => {
        console.log("content updated");
        this.props.pageActions.refreshCurrentPageEntity();
    };

    render() {
        const moderator = this.props.user.currentUser?.member?.membershipLevel?.configuration?.isModerator ||
            this.canModerate();

        if (!moderator || this.props.datacontext.entityKind === 'Forum') {
            return null;
        }

        return <ModeratorToolbar
            urlservice={this.props.urlservice}
            left={this.state.left}
            right={this.state.right} 
        />;
    }

    canModerate() {
        const isGroupModerator = this.props.customcontext?.groupMember?.isModerator;
        if (this.props.customcontext?.groupId) {
            return isGroupModerator;
        }
        
        if (!this.props.user.currentUser?.member?.membershipLevel?.configuration?.content?.allowPublish &&
            !this.props.user.currentUser?.member?.membershipLevel?.configuration?.content?.validateBeforePublishing) {
            return false;
        }

        switch (this.props.datacontext.entityKind) {
            case 'Article':
                return this.props.datacontext?.entity?.memberId === this.props.user.currentUser?.member.id &&
                    !!this.props.user.currentUser?.member?.membershipLevel?.configuration?.content?.articles;

            case 'DocumentCommunity':
                return this.props.datacontext?.entity?.memberId === this.props.user.currentUser?.member.id &&
                    !!this.props.user.currentUser?.member?.membershipLevel?.configuration?.content?.docs;

            case 'Vod':
                return this.props.datacontext?.entity?.memberId === this.props.user.currentUser?.member.id &&
                    !!this.props.user.currentUser?.member?.membershipLevel?.configuration?.content?.vods;

            case 'IWEvent':
                return this.props.datacontext?.entity?.memberId === this.props.user.currentUser?.member.id &&
                    !!this.props.user.currentUser?.member?.membershipLevel?.configuration?.content?.events;

            case 'Podcast':
                return this.props.datacontext?.entity?.memberId === this.props.user.currentUser?.member.id &&
                    !!this.props.user.currentUser?.member?.membershipLevel?.configuration?.content?.podcasts;
        }
    }
}
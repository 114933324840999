import * as React from 'react';
import { registerEntityAction } from '@@pages/entitydetail/actionscatalog';
import { registerBloc } from '@@pages/blocscatalog';
import { registerItemTemplatePart } from '@inwink/itemtemplate/templatecatalog';
import { BlocCommunityEvents, blocEventsData } from './contents/blocs/teaserevents';
import { BlocCommunityTopic, blocTopicData } from './contents/blocs/teasertopics';
import { BlocCommunityTeaserContent, blocTeaserContentData } from './contents/blocs/teasercontent';
import { BlocCommunitySpotlights, blocSpotlightsData } from './contents/blocs/spotlights';
import { BlocCommunityContentComments, blocContentCommentsData } from './contents/blocs/contentcomments';
import { BlocCommunityUserKpis } from './contents/blocs/userkpis';
import { ContentAdmin } from './contents/blocs/contentadmin';
import { ContentLike } from './contents/actions/content/like';
import { Bookmark } from './contents/actions/content/bookmark';
import { EventWebsite } from './contents/actions/content/iwevent/actions/eventwebsite';
import { EventICS } from './contents/actions/content/iwevent/actions/eventics';
import { TopicBookmark } from './contents/actions/topicbookmark';
import { InfoComments } from './contents/templates/infocomments';
import { InfoLikes } from './contents/templates/infolikes';
import { Topics } from './contents/templates/topics';
import { ContentActions } from './contents/templates/contentitemactions';
import { TopicActions } from './contents/templates/topicitemactions';
import { ContentStatus } from './contents/templates/contentstatus';
import { GroupRegistration } from './contents/actions/groupregistration';
import { ForumSubjectActions } from './contents/templates/forumsubjectactions';
import { ForumSubjectNavigation } from './contents/templates/forumsubjectnavigation';
import { BookmarkContent } from './contents/actions/content/bookmarkcontent';
import { UnBookmarkContent } from './contents/actions/content/unbookmarkcontent';
import { BookmarkTopic } from './contents/actions/bookmarktopic';
import { UnBookmarkTopic } from './contents/actions/unbookmarktopic';
import { LikeContent } from './contents/actions/content/likecontent';
import { UnlikeContent } from './contents/actions/content/unlikecontent';
import { CompanyItem } from './contents/templates/companyitem';
import { SponsorContent } from './contents/templates/sponsorcontent';
import { UnRegisterIWEvent } from './contents/actions/content/iwevent/unregisteriwevent';
import { RegisterIWEvent } from './contents/actions/content/iwevent/registeriwevent';
import { EventLocation } from './contents/actions/content/iwevent/actions/eventlocation';
import { BlocTeaserEventRegistered, blocTeaserEventRegisteredData } from './contents/blocs/teasereventregistered';
import { EventJoinWebinar, EventReplay } from './contents/actions/content/iwevent/actions/eventwebinar';

registerItemTemplatePart("topics", Topics);
registerItemTemplatePart("infolikes", InfoLikes);
registerItemTemplatePart("infocomments", InfoComments);
registerItemTemplatePart("topicitemactions", TopicActions);
registerItemTemplatePart("forumsubjectitemactions", ForumSubjectActions);
registerItemTemplatePart("forumsubjectitemnavigation", ForumSubjectNavigation);
registerItemTemplatePart("contentitemactions", ContentActions);
registerItemTemplatePart("contentstatus", ContentStatus);
registerItemTemplatePart("companyitem", CompanyItem);
registerItemTemplatePart("sponsorcontent", SponsorContent);

registerEntityAction("contentlike", {
    component: ContentLike
});
registerEntityAction("likecontent", {
    component: LikeContent
});
registerEntityAction("unlikecontent", {
    component: UnlikeContent
});
registerEntityAction("contentbookmark", {
    component: Bookmark
});
registerEntityAction("bookmarkcontent", {
    component: BookmarkContent
});
registerEntityAction("unbookmarkcontent", {
    component: UnBookmarkContent
});
registerEntityAction("registeriwevent", {
    component: RegisterIWEvent
});
registerEntityAction("unregisteriwevent", {
    component: UnRegisterIWEvent
});
registerEntityAction("eventwebsite", {
    component: EventWebsite
});
registerEntityAction("eventics", {
    component: EventICS
});
registerEntityAction("eventlocation", {
    component: EventLocation
});
registerEntityAction("eventjoinwebinar", {
    component: EventJoinWebinar
});
registerEntityAction("eventreplay", {
    component: EventReplay
});
registerEntityAction("topicbookmark", {
    component: TopicBookmark
});
registerEntityAction("bookmarktopic", {
    component: BookmarkTopic
});
registerEntityAction("unbookmarktopic", {
    component: UnBookmarkTopic
});
registerEntityAction("groupregistration", {
    component: GroupRegistration
});

registerBloc("commu-contentadmin", {
    headerComponents: [ContentAdmin],
    component: () => null
});

registerBloc("commu-spotlights", {
    // eslint-disable-next-line react/display-name
    component: BlocCommunitySpotlights,
    datainit: blocSpotlightsData
});

registerBloc("commu-teaseriwevents", {
    // eslint-disable-next-line react/display-name
    component: (props) => React.createElement(
        BlocCommunityTeaserContent, { ...props, contentType: "IWEvent", detailpage: "events" }
    ),
    datainit: blocTeaserContentData("IWEvent")
});

registerBloc("commu-teaserarticles", {
    // eslint-disable-next-line react/display-name
    component: (props) => React.createElement(
        BlocCommunityTeaserContent, { ...props, contentType: "Article", detailpage: "articles" }
    ),
    datainit: blocTeaserContentData("Article")
});

registerBloc("commu-teaservods", {
    // eslint-disable-next-line react/display-name
    component: (props) => React.createElement(
        BlocCommunityTeaserContent, { ...props, contentType: "Vod", detailpage: "vods" }
    ),
    datainit: blocTeaserContentData("Vod")
});

registerBloc("commu-teaserpodcasts", {
    // eslint-disable-next-line react/display-name
    component: (props) => React.createElement(
        BlocCommunityTeaserContent, { ...props, contentType: "Podcast", detailpage: "podcasts" }
    ),
    datainit: blocTeaserContentData("Podcast")
});

registerBloc("commu-teaserdocs", {
    // eslint-disable-next-line react/display-name
    component: (props) => React.createElement(
        BlocCommunityTeaserContent, { ...props, contentType: "DocumentCommunity", detailpage: "documents" }
    ),
    datainit: blocTeaserContentData("DocumentCommunity")
});

registerBloc("commu-userkpis", {
    component: BlocCommunityUserKpis
});

registerBloc("commu-teasertopics", {
    component: BlocCommunityTopic,
    datainit: blocTopicData
});

registerBloc("commu-teaserevents", {
    component: BlocCommunityEvents,
    datainit: blocEventsData
});

registerBloc("commu-contentcomment", {
    component: BlocCommunityContentComments,
    datainit: blocContentCommentsData
});

registerBloc("commu-teaseriweventregistered", {
    component: BlocTeaserEventRegistered,
    datainit: blocTeaserEventRegisteredData
});
import * as React from 'react';
import { IEntityDetailActionProps } from '@@pages/entitydetail/entitydetail.actions';
import type { ICommunityIWEvent } from '@@community/data';
import { WebinarPlayer } from '@@modules/community/features/contents/components/webinarplayer';
import { NavLink } from 'react-router-dom';

interface IEventWebinarProps extends IEntityDetailActionProps {
}

export function EventJoinWebinar(props: IEventWebinarProps) {
    const content: ICommunityIWEvent = props.datacontext.entity;

    if (!content || content.eventType != "Webinar") {
        return null;
    }
    const [started, setStarted] = React.useState(false);
    let speakers = (content.speakers) || [];
    if (content.moderators) {
        speakers = [...speakers, ...content.moderators];
    }
    const user = props.datacontext.userstate;
    const url = (!speakers || !speakers.some((s) => user.currentUser?.member?.id === s.id))
        ? props.urlservice.pageUrl(`webinarplayer/${content.id}`)
        : props.urlservice.pageUrl(`webinarstudio/${content.id}`);

    const classNames = ["webinar-join"];

    if (props.action?.displayOptions?.isSecondary) {
        classNames.push('lightbtn');
    }

    return <>
        <WebinarPlayer {...props} webinar={content} currentuser={props.user} hidden onWebinarStart={() => {
            setStarted(true);
        }} />
        {started && <NavLink role="button"
            className={classNames?.join(' ')}
            to={url}
        >
            <span className="text">
                {props.action.icon?.className && <i className={props.action.icon.className} />}
                {props.action.label?.[props.i18nHelper.i18n.currentLanguageCode]}
            </span>
        </NavLink>}
    </>;
}

export function EventReplay(props: IEventWebinarProps) {
    const content: ICommunityIWEvent = props.datacontext.entity;

    if (!content || !content.vodId) {
        return null;
    }
    const member = props.user.currentUser?.member;
    const moderator = member?.membershipLevel?.configuration?.isModerator;

    if (content.vod) {
        if (moderator || content.vod?.status === "Published") {
            const classNames = ["webinar-replay"];

            if (props.action?.displayOptions?.isSecondary) {
                classNames.push('lightbtn');
            }

            return <NavLink role="button"
                className={classNames?.join(' ')}
                to={props.urlservice.pageUrl("vods/" + content.vodId)}
            >
                <span className="text">
                    {props.action.icon?.className && <i className={props.action.icon.className} />}
                    {props.action.label?.[props.i18nHelper.i18n.currentLanguageCode]}
                </span>
            </NavLink>;
        }
    }

    return null;
}
import * as React from 'react';
import { AsyncButton } from '@inwink/buttons/asyncbutton';
import { IEntityDetailActionProps } from '@@pages/entitydetail/entitydetail.actions';
import { unregisterIWEvent } from '@@community/api/contents';
import { AppTextLabel } from '@inwink/i18n';
import { actions as pageActions } from '@@services/pageservice';
import { connectwith } from '@inwink/react-utils/decorators/connectwith';
import { bindActionCreators } from 'redux';
import { ICommunityContent, ICommunityIWEvent } from '@@modules/community/data';
import moment from 'moment';
import { unregisterEvent } from '@@modules/community/api/events';
import { debounce } from '@inwink/react-utils/decorators/debounce';
import { markIWEventInwinkSetIsRegistered } from './registeriwevent';

interface IUnRegisterIWEventProps extends IEntityDetailActionProps {
}

@connectwith(() => {
    return {
    };
}, (dispatch) => {
    return {
        pageActions: bindActionCreators(pageActions, dispatch),
    };
})

export class UnRegisterIWEvent extends React.PureComponent<IUnRegisterIWEventProps, any> {
    onClick = (arg: React.MouseEvent<any>) => {
        arg.preventDefault();
        const content: ICommunityIWEvent = this.props.datacontext.entity;

        if (content?.isRegistered) {
            return import("@@community/features/user/useractions").then((mod) => {
                const targ = arg.target as HTMLElement;
                return mod.userActions.promptUserAccess(this.props.i18nHelper,
                    targ)(this.props.store.dispatch, this.props.store.getState)
                    .then((canAct) => {
                        if (canAct) {
                            let promise;
                            if (this.inwinkRegistration()) {
                                promise = unregisterIWEvent(
                                    this.props.community.requestManagers.apiFront,
                                    content.inwinkEventId,
                                    this.props.community.tenantid
                                );
                            } else if (this.registration()) {
                                promise = unregisterEvent(this.props.community.requestManagers.apiFront, content.id);
                            }
                            if (promise) {
                                return new Promise((resolve, reject) => {
                                    promise.then(() => {
                                        markIWEventInwinkSetIsRegistered(this.props.user, content,
                                            this.props.store, false, this.props.pageActions);
                                    }, (err) => {
                                        console.error("error removing register iwevent", err);
                                        inwink.toast.add(
                                            {
                                                title: this.props.i18nHelper.translate(
                                                    "actions.content.iwevent.unregistermember.error")
                                            },
                                            "error");
                                        reject(err);
                                    });
                                });
                            }
                        }
                    });
            });
        }
    };

    componentDidUpdate(prevProps: IUnRegisterIWEventProps) {
        const oldContent: ICommunityContent = prevProps.datacontext.entity;
        const newContent: ICommunityContent = this.props.datacontext.entity;
        if (oldContent && newContent && oldContent.isRegistered != newContent.isRegistered) {
            if (oldContent.isRegistered && !newContent.isRegistered) {
                this.promptSuccess();
            }
        }
    }

    @debounce(500)
    promptSuccess() {
        inwink.toast.add(
            { title: this.props.i18nHelper.translate("actions.content.iwevent.unregistermember") },
            "success");
    }

    registration = () => {
        const iwevent = (this.props.datacontext?.entity as ICommunityIWEvent);
        const registration = iwevent?.eventType == "SmallEvent"
            || iwevent?.eventType == "Webinar";
        return registration;
    };

    inwinkRegistration = () => {
        const iwevent = (this.props.datacontext?.entity as ICommunityIWEvent);
        const inwinkRegistration = iwevent?.eventType == "InwinkEvent"
            && (iwevent?.inwinkEventRegistrationMode == "Direct"
                || (iwevent?.inwinkEventRegistrationMode == "EventWebsite" && iwevent?.inwinkEventUrl));
        return inwinkRegistration;
    };

    render() {
        if (!this.inwinkRegistration() && !this.registration()) {
            return null;
        }
        const iwevent = (this.props.datacontext?.entity as ICommunityIWEvent);

        if (iwevent?.isRegistered && this.props.user.currentUser?.member?.id
            && iwevent?.$endDate?.isAfter?.(moment())) {
            const classNames = ["checked unregisteriwevent"];

            if (this.props.action?.displayOptions?.isSecondary) {
                classNames.push('lightbtn');
            }

            return <AsyncButton className={classNames.join(' ')} onClick={this.onClick}>
                {this.props.action?.icon?.className
                    ? <i className={this.props.action.icon.className} />
                    : null}
                <AppTextLabel i18n={
                    this.props.i18nHelper?.translateBag(this.props.action?.label)
                    || "community.content.actions.unregisteriwevent"} />
            </AsyncButton>;
        }

        return null;
    }
}

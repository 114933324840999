import * as React from 'react';
import { Link } from 'react-router-dom';
import { DynLabel } from '@@services/i18nservice';
import type { ICommunityTopic } from '@@community/data';
import type { ItemTemplateProps } from '@@components/templates/itemtemplate.props';
import { topicQuery } from '@@modules/community/api/topic';

export interface ITopicsProps extends ItemTemplateProps {}

interface ITopicsState {
    visualConfigs: { [key: string]: { color?: string, textColor?: string } };
}

export class Topics extends React.PureComponent<ITopicsProps, ITopicsState> {
    constructor(props: ITopicsProps) {
        super(props);
        this.state = {
            visualConfigs: {}
        };
    }

    componentDidMount() {
        const visualConfigs: { [key: string]: { color?: string, textColor?: string } } = {};
        const topicsToFetch: string[] = [];
        
        this.props.data?.topics?.forEach((topic) => {
            if (!topic.visualConfig) {
                topicsToFetch.push(topic.id);
            } else {
                visualConfigs[topic.id] = {
                    color: topic.visualConfig?.color,
                    textColor: topic.visualConfig?.textColor
                };
            }
        });
    
        if (topicsToFetch.length > 0) {
            const options = {
                filters: {
                    id: { $in: topicsToFetch }
                }
            };
    
            topicQuery(this.props.datacontext.communitystate.requestManagers.apiFront, options)
                .then((res) => {
                    if (res) {
                        res.data.forEach((item) => {
                            visualConfigs[item.id] = {
                                color: item.visualConfig?.color,
                                textColor: item.visualConfig?.textColor
                            };
                        });
                        this.setState({ visualConfigs });
                    }
                })
                .catch((error) => {
                    console.error("Error fetching visual configurations:", error);
                });
        } else {
            this.setState({ visualConfigs });
        }
    }   

    render() {
        if (!this.props.data?.topics) {
            return null;
        }

        const topics: ICommunityTopic[] = this.props.data?.topics;        
        const { visualConfigs } = this.state;

        if (this.props.template?.properties?.clickable) {
            return (
                <div className="topics">
                    {topics.map((t) => {
                        const bkgColor = { backgroundColor: visualConfigs[t.id]?.color };
                        const textColor = { color: visualConfigs[t.id]?.textColor };
                        return (
                            <Link
                                key={t.id}
                                className="topicstags bloc-accentbg keywordbubble clickable"
                                style={bkgColor}
                                to={this.props.urlservice.pageUrl("topics/" + t.id)}
                                role="link"
                            >
                                <DynLabel 
                                    component="span" 
                                    i18n={t.title}
                                    style={textColor}
                                />
                            </Link>
                        );
                    })}
                </div>
            );
        }

        return (
            <div className="topics">
                {topics.map((t) => {
                    const bkgColor = { backgroundColor: visualConfigs[t.id]?.color };
                    const textColor = { color: visualConfigs[t.id]?.textColor };
                    return (
                        <span
                            key={t.id}
                            className={this.props.template.className}
                            style={bkgColor}
                        >
                            <DynLabel 
                                component="span" 
                                i18n={t.title}
                                style={textColor} 
                            />
                        </span>
                    );
                })}
            </div>
        );
    }
}

import * as React from "react";
import { bindActionCreators } from 'redux';
import { withI18nHelper } from '@inwink/i18n/reactcontext';
import { AsyncButton } from '@inwink/buttons';
import { Entities } from '@inwink/entities/entities';
import { connectwith } from '@inwink/react-utils/decorators/connectwith';
import { userMessageActions } from '@@services/usermessageactions';
import type { States } from '@@services/services';
import "./moderatortoolbar.less";

export interface IModeratorToolbarItemComponentProps {
    i18nHelper: Entities.i18nHelper;
    userMessageActions: typeof userMessageActions;
}

export interface IModeratorToolbarItem {
    id?: string;
    i18n?: string;
    callback?: (arg?: React.MouseEvent<any>) => void | Promise<any>;
    component?: (props: IModeratorToolbarItemComponentProps) => any;
}

export interface IModeratorToolbarProps {
    className?: string;
    data?: any;
    left: IModeratorToolbarItem[];
    right: IModeratorToolbarItem[];
    user?: States.IAppUserState;
    urlservice?: States.IAppUrlContext;
    i18nHelper?: Entities.i18nHelper;
    userMessageActions?: typeof userMessageActions;
}

@connectwith((state) => {
    return {
        user: state.user
    };
}, (dispatch) => {
    return {
        userMessageActions: bindActionCreators(userMessageActions, dispatch)
    };
})
@withI18nHelper()
export class ModeratorToolbar extends React.Component<IModeratorToolbarProps, any> {
    renderItems(className: string, items: IModeratorToolbarItem[]) {
        if (!items || !items.length) {
            return null;
        }

        return <div className={className}>
            {items.map((item, idx) => {
                if (item.component) {
                    return <div
                        key={item.id + "item" + idx}
                        className={"toolbaritem" + (item.id ? " toolbaritem-" + item.id : "")}
                    >
                        {React.createElement(item.component, {
                            i18nHelper: this.props.i18nHelper,
                            userMessageActions: this.props.userMessageActions
                        })}
                    </div>;
                }

                if (item.callback) {
                    return <AsyncButton
                        key={item.id + "item" + idx}
                        i18nLabel={item.i18n}
                        className={item.id ? "toolbaritem-" + item.id : ""}
                        onClick={(arg) => {
                            arg.preventDefault();
                            arg.stopPropagation();
                            return item.callback(arg);
                        }}
                    />;
                }

                return null;
            })}
        </div>;
    }

    render() {
        return <header className={"community-moderatortoolbar" + (this.props.className ? " " + this.props.className : "")}>
            {this.renderItems("left", this.props.left)}
            {this.renderItems("right", this.props.right)}
        </header>;
    }
}

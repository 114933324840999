import * as React from 'react';
import * as History from 'history';
import { connect } from 'react-redux';
import { withI18nHelper } from '@inwink/i18n';
import type { Entities } from '@inwink/entities/entities';
import type { ItemTemplateProps } from '@@components/templates/itemtemplate.props';
import type { States } from '@@services/services';
import { wrapReduxStore, IInwinkStore } from '@@store/index';
import { like, unlike, bookmark, removeBookmark } from '@@community/api/contents';
import type { ICommunityContent } from '@@community/data';
import { withRouter } from 'react-router-dom';
import { getDisplayFor, IActionDisplay, RenderAction } from './renderaction';

export interface IContentActionsProps extends ItemTemplateProps {
    i18n?: States.i18nState;
    community?: States.ICommunityState;
    i18nHelper?: Entities.i18nHelper;
    store?: IInwinkStore;
    user?: States.IAppCurrentUser;
    history?: History.History;
}
interface IContentActionsState {
    like: IActionDisplay;
    bookmark: IActionDisplay;
}
@withI18nHelper()
class ContentActionsComponent extends React.Component<IContentActionsProps, IContentActionsState> {
    constructor(props: IContentActionsProps) {
        super(props);

        this.state = {
            like: getDisplayFor(props, "like", "likeActionDisplay"),
            bookmark: getDisplayFor(props, "bookmark", "bookmarkActionDisplay")
        };
    }

    like = (arg: React.MouseEvent<any>) => {
        arg.preventDefault();
        arg.stopPropagation();
        if (!this.props.dataKind) {
            return Promise.reject(new Error("content type not specified"));
        }
        return import("@@community/features/user/useractions").then((mod) => {
            const targ = arg.target as HTMLElement;
            return mod.userActions.promptUserAccess(this.props.i18nHelper,
                targ)(this.props.store.dispatch, this.props.store.getState)
                .then((canAct) => {
                    if (canAct) {
                        return like(
                            this.props.community.requestManagers.apiFront, this.props.dataKind as any, this.props.data.id
                        ).then((res: any) => {
                            this.props.data.info = res;
                            this.props.data.isLiked = true;
                            this.props.onDataUpdate(this.props.data);
                        }, (err) => {
                            console.error("error adding like", err);
                        });
                    }
                });
        });
    };

    unlike = (arg: React.MouseEvent<any>) => {
        arg.preventDefault();
        arg.stopPropagation();
        if (!this.props.dataKind) {
            return Promise.reject(new Error("content type not specified"));
        }
        return unlike(
            this.props.community.requestManagers.apiFront, this.props.dataKind as any, this.props.data.id
        ).then((res: any) => {
            this.props.data.info = res;
            this.props.data.isLiked = false;
            this.props.onDataUpdate(this.props.data);
        }, (err) => {
            console.error("error removing like", err);
        });
    };

    bookmark = (arg: React.MouseEvent<any>) => {
        arg.preventDefault();
        arg.stopPropagation();
        let dataKind = this.props.dataKind || (this.props.data?.type?.toLowerCase());
        if (!dataKind) {
            return Promise.reject(new Error("content type not specified"));
        }
        if (dataKind === "document") {
            dataKind = dataKind + "Community";
        }
        return import("@@community/features/user/useractions").then((mod) => {
            const targ = arg.target as HTMLElement;
            return mod.userActions.promptUserAccess(this.props.i18nHelper,
                targ)(this.props.store.dispatch, this.props.store.getState)
                .then((canAct) => {
                    if (canAct) {
                        return bookmark(
                            this.props.community.requestManagers.apiFront, dataKind, this.props.data.id
                        ).then((res: any) => {
                            if (res?.bookmarked) {
                                this.props.data.isBookmarked = true;
                                this.props.onDataUpdate(this.props.data);
                            }
                        }, (err) => {
                            console.error("error adding bookmark", err);
                        });
                    }
                });
        });
    };

    unbookmark = (arg: React.MouseEvent<any>) => {
        arg.preventDefault();
        arg.stopPropagation();
        let dataKind = this.props.dataKind || (this.props.data?.type?.toLowerCase());
        if (!dataKind) {
            return Promise.reject(new Error("content type not specified"));
        }
        if (dataKind === "document") {
            dataKind = dataKind + "Community";
        }
        return removeBookmark(
            this.props.community.requestManagers.apiFront, dataKind as any, this.props.data.id
        ).then((res: any) => {
            if (res?.bookmarked) {
                this.props.data.isBookmarked = false;
                this.props.onDataUpdate(this.props.data);
            }
        }, (err) => {
            console.error("error removing bookmark", err);
        });
    };

    comment = (arg: React.MouseEvent<any>) => {
        arg.preventDefault();
        arg.stopPropagation();
        if (!this.props.dataKind) {
            return Promise.reject(new Error("content type not specified"));
        }
        return import("@@community/features/user/useractions").then((mod) => {
            const targ = arg.target as HTMLElement;
            return mod.userActions.promptUserAccess(this.props.i18nHelper,
                targ)(this.props.store.dispatch, this.props.store.getState)
                .then((canAct) => {
                    if (canAct) {
                        const linkto = this.props.itemlinkto;
                        let to;
                        if (typeof linkto == "string") {
                            to = linkto;
                        } else {
                            const res = linkto(this.props.data);
                            if (typeof res === "string") {
                                to = res;
                            } else if (typeof res === "object") {
                                to = res.url;
                            }
                        }
                        this.props.history.push(to);
                    }
                });
        });
    };

    render() {
        const content: ICommunityContent = this.props.data;
        return <div className={"contentitemactions" + (this.props.template.className ? " " + this.props.template.className : "")}>
            <RenderAction
                i18nHelper={this.props.i18nHelper}
                className="act-bookmark"
                display={this.state.bookmark}
                isOn={content.isBookmarked}
                iconOn="inwink-star"
                titleOn="community.content.actions.unbookmark"
                iconOff="inwink-star-outline"
                titleOff="community.content.actions.bookmark"
                callbackOn={this.unbookmark}
                callbackOff={this.bookmark}
            />
            {!this.props.data.disableLikes ? <RenderAction
                i18nHelper={this.props.i18nHelper}
                className="act-like"
                display={this.state.like}
                isOn={content.isLiked}
                iconOn="inwink-thumbup-full"
                titleOn="community.content.actions.unlike"
                iconOff="inwink-thumbup-outline"
                titleOff="community.content.actions.like"
                callbackOn={this.unlike}
                callbackOff={this.like}
                count={content.info?.likes} /> : null
            }
            {!this.props.data.disableComments ? <RenderAction
                i18nHelper={this.props.i18nHelper}
                className="act-comment"
                display={this.state.like}
                isOn={content.info?.comments > 0}
                iconOn="inwink-social_wall"
                titleOn="community.content.actions.comment"
                iconOff="inwink-social_wall"
                titleOff="community.content.actions.comment"
                callbackOn={this.comment}
                callbackOff={this.comment}
                count={content.info?.comments} /> : null
            }
        </div>;
    }
}

function mapStateToProps(state: States.IAppState) {
    return {
        community: state.community
    };
}

function mapDispatchToProps() {
    return {};
}

export const ContentActions: React.Component<IContentActionsProps> = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(wrapReduxStore(ContentActionsComponent)) as any) as any;


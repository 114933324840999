import type { ItemTemplateProps } from '@@components/templates/itemtemplate.props';
import * as History from 'history';
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { AppTextLabel } from '@@services/i18nservice';
import { States } from '@@services/services';

import './forumsubjectnavigation.less';

export interface IForumSubjectNavigationProps extends ItemTemplateProps {
    history: History.History;
    location: States.ILocation;
}

class ForumSubjectNavigationComponent extends React.Component<IForumSubjectNavigationProps, any> {
    goBack = () => {
        const searchArgs = this.props.location?.search || '';
        const forumId = this.props.datacontext?.entity?.forumId;
        const url = this.props.urlservice.pageUrl(`forums/${forumId}${searchArgs}`);
        this.props.history?.push(url);
    };

    render() {
        return <span className='navigation-action clickable' onClick={this.goBack}>
            <i className="inwink-back" />
            <AppTextLabel i18n='community.forumsubject.actions.backtosubjects' />
        </span>;
    }
}

export const ForumSubjectNavigation: new (any)
=> React.Component<IForumSubjectNavigationProps, any> = connect(
    null,
    null,
)(withRouter(ForumSubjectNavigationComponent as any)) as any;


import * as React from 'react';
import { DynLabel } from '@@services/i18nservice';
import type { States } from '@@services/services';
import { Loader } from '@inwink/loader';
import { motion, MotionProps, AnimationProps } from 'framer-motion';
import type { IUserKpi } from './index';

const animateItemsInitial: MotionProps["initial"] = {
    scale: 0.5,
    opacity: 0,
};

const animateItemsAnimate: AnimationProps["animate"] = {
    scale: 1,
    opacity: 1
};

const animateItemsTransition: AnimationProps["transition"] = {
    duration: 0.4,
    easings: "easeOut"
};

export interface IKpiProps {
    kpi: IUserKpi;
    community: States.ICommunityState;
    user: States.IAppUserState;
}

export interface IKpiState {
    loading: boolean;
    val?: number;
}

export class Kpi extends React.PureComponent<IKpiProps, IKpiState> {
    constructor(props: IKpiProps) {
        super(props);

        this.state = {
            loading: true
        };
    }

    componentDidMount() {
        this.props.kpi.callback(this.props).then((res) => {
            this.setState({
                val: res,
                loading: false
            });
        });
    }

    render() {
        return <div className={"kpi " + this.props.kpi.className || "card bloc-accentbg"}>
            {this.props.kpi?.icon?.className
                ? <i className={this.props.kpi?.icon?.className} />
                : null}
            <div className="kpi-wrapper">
                <div className="kpival">
                    {this.state.loading ? <Loader message="" /> : <motion.div
                        className="val"
                        initial={animateItemsInitial}
                        animate={animateItemsAnimate}
                        transition={animateItemsTransition}
                    >{this.state.val || "0"}</motion.div>}
                </div>
                <DynLabel i18n={this.props.kpi.label} className="kpilabel" />
            </div>

        </div>;
    }
}

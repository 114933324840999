import * as React from 'react';
import type { ItemTemplateProps } from '@@components/templates/itemtemplate.props';

export interface IInfoCommentsProps extends ItemTemplateProps {

}

export class InfoComments extends React.PureComponent<IInfoCommentsProps, any> {
    render() {
        if (!this.props.data?.info?.comments) {
            return null;
        }

        return <span className="infocomments">
            <i className="inwink-social_wall" /> {this.props.data?.info?.comments}
        </span>;
    }
}

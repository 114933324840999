import * as React from 'react';
import { IEntityDetailActionProps } from '@@pages/entitydetail/entitydetail.actions';
import type { ICommunityTimedContent } from '@@community/data';
import { AppTextLabel } from '@inwink/i18n';

interface IEventWebsiteProps extends IEntityDetailActionProps {
}

export function EventWebsite(props: IEventWebsiteProps) {
    const content: ICommunityTimedContent = props.datacontext.entity;

    const urlEvent = content?.eventType === "ExternalEvent" ? content.externalUrl : content?.inwinkEventUrl;

    if (!content || !urlEvent) {
        return null;
    }

    const classNames = ["website"];

    if (props.action?.displayOptions?.isSecondary) {
        classNames.push('lightbtn');
    }

    return <a
        role="button"
        target="_blank"
        rel="noreferrer"
        href={urlEvent}
        className={classNames?.join(' ')}
    >
        <span className="text">
            {props?.action?.icon?.className
                ? <i className={props?.action?.icon?.className} />
                : null}
            <AppTextLabel i18n={
                props?.i18nHelper?.translateBag(props?.action?.label)
                || "community.content.actions.eventwebsite"} />
        </span>
    </a>;
}

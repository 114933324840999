import * as React from 'react';
import type { VisualTheme } from '@inwink/entities/visualtheme';
import type { States } from '@@services/services';
import { IDynamicBlocWithProps, DynamicBloc } from '@@pages/common';
import { BlocTitle } from '@@pages/common.title';
import { CommentsList } from '@@community/components/comments/commentslist';
import type { ICommentsDatasource } from '@@community/data';
import { getCommentsDatasource } from '@@community/features/contents/commentsdatasource';
import { MemberInfoContextSource } from '@@community/components/memberinfocontextsource';

export interface IBlocCommunityContentCommentsProps
    extends IDynamicBlocWithProps<IBlocCommunityContentCommentsBlocState, IBlocCommunityContentCommentsBlocProperties> {

}

export interface IBlocCommunityContentCommentsBlocState {

}

export interface IBlocCommunityContentCommentsBlocProperties {
}

export function blocContentCommentsData(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    page: States.ICurrentPageState,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    statedata: IBlocCommunityContentCommentsBlocState,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    blc: VisualTheme.IBlocContentTemplateBase,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    state: States.IAppState
) {
}

export class BlocCommunityContentComments extends React.PureComponent<IBlocCommunityContentCommentsProps, any> {
    datasource: ICommentsDatasource;

    constructor(props) {
        super(props);
        this.state = {};
        this.buildDatasource();
    }

    buildDatasource() {
        if (this.props.page.context.entityKind) {
            const entityId = this.props.page.context.entityid;

            this.datasource = getCommentsDatasource(
                this.props.community, this.props.user,
                this.props.page.context.entityKind as any,
                entityId,
                this.props.customcontext?.group
            );            
        }
    }

    componentDidUpdate() {
        if (!this.datasource && this.props.page.context.entityKind) {
            this.buildDatasource();
        }
    }

    render() {
        const isPublicGroup = this.props.customcontext?.group?.allowPublicView;
        if ((!this.datasource
            || !this.props.user.currentUser
            || this.props.datacontext?.entity?._hasPartialContent
            || this.props.datacontext?.entity?.disableComments) && !isPublicGroup
        ) {
            return null;
        }

        return <MemberInfoContextSource customcontext={this.props.customcontext}>
            <DynamicBloc {...this.props}>
                <BlocTitle {...this.props} className="bloc-header" />
                <div className="bloc-content">
                    <CommentsList
                        datacontext={this.props.datacontext}
                        user={this.props.user}
                        community={this.props.community}
                        groupId={this.props.customcontext?.group?.id}
                        isPublicGroup={this.props.customcontext?.group?.allowPublicView}
                        datasource={this.datasource}
                        showAddComment={true}
                    />
                </div>
            </DynamicBloc>
        </MemberInfoContextSource>;
    }
}

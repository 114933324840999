import * as React from 'react';
import { AsyncButton } from '@inwink/buttons/asyncbutton';
import { IEntityDetailActionProps } from '@@pages/entitydetail/entitydetail.actions';
import { bookmark, removeBookmark } from '@@community/api/topic';
import type { ICommunityContent } from '@@community/data';
import { AppTextLabel } from '@inwink/i18n';
import { connectwith } from '@inwink/react-utils/decorators/connectwith';
import { bindActionCreators } from 'redux';
import { actions as pageActions } from '@@services/pageservice';


interface IBookmarkProps extends IEntityDetailActionProps {
}

@connectwith(() => {
    return {
    };
}, (dispatch) => {
    return {
        pageActions: bindActionCreators(pageActions, dispatch),
    };
})

export class TopicBookmark extends React.PureComponent<IBookmarkProps, any> {
    render() {
        const content: ICommunityContent = this.props.datacontext.entity;

        const onClick = (arg: React.MouseEvent<any>) => {
            arg.preventDefault();

            if (content.isBookmarked) {
                return removeBookmark(
                    this.props.community.requestManagers.apiFront, this.props.entityId
                ).then((res) => {
                    if (res?.bookmarked) {
                        this.props.pageActions.patchCurrentPageEntity({ isBookmarked: false });

                    }
                }, (err) => {
                    console.error("error removing bookmark", err);
                });
            }

            return import("@@community/features/user/useractions").then((mod) => {
                const targ = arg.target as HTMLElement;
                return mod.userActions.promptUserAccess(
                    this.props.i18nHelper, targ)(this.props.store.dispatch, this.props.store.getState)
                    .then((canAct) => {
                        if (canAct) {
                            return bookmark(
                                this.props.community.requestManagers.apiFront, this.props.entityId
                            ).then((res) => {
                                if (res?.bookmarked) {
                                    this.props.pageActions.patchCurrentPageEntity({ isBookmarked: true });
                                }
                            }, (err) => {
                                console.error("error adding bookmark", err);
                            });
                        }
                    });
            });
        };

        if (!content) {
            return null;
        }

        return <AsyncButton
            className={"act-bookmark" + (content.isBookmarked ? " checked" : "")}
            onClick={onClick}
        >
            <i className={content.isBookmarked
                ? "inwink-star"
                : "inwink-star-outline"}
            />
            {content.isBookmarked
                ? <AppTextLabel i18n="community.content.actions.unbookmark" />
                : <AppTextLabel i18n="community.content.actions.bookmark" />}
        </AsyncButton>;
    }
}   

import * as React from 'react';
import * as moment from 'moment';
import type { ItemTemplateProps } from '@@components/templates/itemtemplate.props';
import type { ICommunityContent } from '@@community/data';
import type { States } from '@@services/services';
import { AppTextLabel } from '@inwink/i18n';
import { connectwith } from '@inwink/react-utils/decorators/connectwith';

interface IContentStatusItemProperties {
    className?: string;
    disabled?: boolean;
    style?: any;
}
interface IContentStatusProperties {
    status: IContentStatusItemProperties;
    newcontent: IContentStatusItemProperties;
}
export interface IContentStatusProps extends ItemTemplateProps {
    userstate: States.IAppUserState;
}

@connectwith((state: States.IAppState) => {
    return {
        userstate: state.user
    };
})
export class ContentStatus extends React.PureComponent<IContentStatusProps, any> {
    render() {
        const items = [];
        const user: States.IAppUserState = this.props.userstate;
        const properties: IContentStatusProperties = this.props.template.properties;
        const content: ICommunityContent = this.props.data;

        if (content?.status !== "Published" && !properties?.status?.disabled) {
            items.push(<span
                key="publication"
                className={"contentstatus publicationstatus " + (properties?.status?.className || "")}
                style={properties?.status?.style}
            >
                <AppTextLabel i18n={"community.content.status." + content.status.toLowerCase()} />
            </span>);
        }

        const hasDates = user.currentUser?.member?.lastVisit && content.createdAt;

        if (!properties?.newcontent?.disabled && hasDates) {
            if (content.createdAt && !content.$createdAt) {
                content.$createdAt = moment(content.createdAt);
            }
            if (user.currentUser?.member?.lastVisit && !user.currentUser?.member?.$lastVisit) {
                user.currentUser.member.$lastVisit = moment(user.currentUser.member.lastVisit);
            }

            if (user.currentUser?.member?.$lastVisit.isBefore(content.$createdAt)) {
                items.push(<span
                    key="newcontent"
                    className={"contentstatus newcontent " + (properties?.newcontent?.className || "")}
                    style={properties?.newcontent?.style}
                >
                    <AppTextLabel i18n="community.content.newcontent" />
                </span>);
            }
        }

        if (items.length === 0) {
            return null;
        }

        return <div className="itemstatus">{items.map((item) => item)}</div>;
    }
}

import * as React from 'react';
import * as shuffle from 'lodash/shuffle';
import { AnimatePresence } from 'framer-motion';
import type { VisualTheme } from '@inwink/entities/visualtheme';
import type { States } from '@@services/services';
import type { IInwinkEntityV3QueryOptions } from '@@api/front';
import { DynamicBloc, IDynamicBlocWithProps } from '@@pages/common';
import { contentQuery } from '@@community/api/contents';
import type { ITeaserItemsBlocCommonProperties } from '@@pages/teaseritems.props';
import type { ICommunityContent, ICommunityContentType } from '@@community/data';
import { eventsQuery } from '@@modules/community/api/events';
import { SpotlightItem, Placeholder } from './spotlightitem';

import "./spotlights.less";

export interface IBlocCommunitySpotlightsProps
    extends IDynamicBlocWithProps<IBlocCommunitySpotlightsBlocState, IBlocCommunitySpotlightsBlocProperties> {
}

export interface ISpotlightItem extends ICommunityContent {
    contentType: ICommunityContentType;
}

export interface IBlocCommunitySpotlightsBlocState {
    // loading?: boolean;
    // contents: ISpotlightItem[];
    // move: number;
    // currentContentIndex: number;
}

export interface IBlocCommunitySpotlightsState {
    loading?: boolean;
    contents: ISpotlightItem[];
    move: number;
    currentContentIndex: number;
}

export interface IBlocCommunitySpotlightsBlocProperties extends ITeaserItemsBlocCommonProperties {
    contentTypes: ICommunityContentType[];
    loop: number;
}

export function blocSpotlightsData(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    page: States.ICurrentPageState,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    statedata: IBlocCommunitySpotlightsBlocState,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    blc: VisualTheme.IBlocContentTemplateBase,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    state: States.IAppState
) {
    // const blocdata = statedata;
    // const bloc = blc;
    // blocdata.loading = true;
    // blocdata.move = 1;
    // blocdata.contents = [];
    // blocdata.currentContentIndex = 0;
}

const titleMax = 250;
const descMax = 250;
export class BlocCommunitySpotlights extends React.PureComponent<IBlocCommunitySpotlightsProps, IBlocCommunitySpotlightsState> {
    currentInterval = null;

    constructor(props: IBlocCommunitySpotlightsProps) {
        super(props);
        this.state = {
            contents: [], // props.blocState.contents,
            currentContentIndex: 0,
            move: 1,
            loading: true // props.blocState.loading
        };
    }

    componentDidMount() {
        if (this.props.template.properties.contentTypes) {
            const allitems: ISpotlightItem[] = [];
            const promises = this.props.template.properties.contentTypes.map((ct) => {
                if (ct === 'Event') {
                    const nbItems = this.props.template.properties.maxItems || 6;
                    const query = {
                        selects: {
                            $all: true
                        },
                        filters: {
                            startDate: {
                                $gt: new Date().toISOString()
                            }
                        },
                        orders: [
                            {
                                desc: false,
                                value: { startDate: {} }
                            }
                        ],
                        page: {
                            index: 0,
                            size: nbItems
                        }
                    };
                    return eventsQuery(
                        this.props.community.requestManagers.apiFront, this.props.community.tenantid, query
                    ).then((res) => {
                        res.data.forEach((event) => {
                            // eslint-disable-next-line no-param-reassign
                            // item.contentType = ct;
                            const itemTitle = {};
                            this.props.i18n.supported.forEach((lng) => {
                                itemTitle[lng] = event.title;
                            });
                            const thumbnail = (event as any).thumbnail;
                            const item: any = Object.assign({}, event, {
                                title: itemTitle,
                                contentType: ct,
                                herobanner: thumbnail ? { url: thumbnail } : null
                            });
                            allitems.push(item);
                        });
                    }, (err) => {
                        console.error("error getting event spotlights", err);
                    });
                }

                return this.loadItems(ct).then((items) => {
                    items.data.forEach((item: ISpotlightItem) => {
                        // eslint-disable-next-line no-param-reassign
                        item.contentType = ct;
                        allitems.push(item);
                    });
                }, (err) => {
                    console.error("error getting content " + ct + " spotlights", err);
                });
            });

            // délai pour debug le placeholder de chargement
            // promises.push(new Promise((resolve) => {
            //     setTimeout(resolve, 10000);
            // }));

            Promise.all(promises).then(() => {
                const resultitems = shuffle(allitems).slice(0, 8);

                this.setState({
                    loading: false,
                    contents: resultitems
                });

                if (!__SERVERSIDE__ && this.props.template.properties.loop) {
                    this.currentInterval = setInterval(this.loop, this.props.template.properties.loop);
                }
            });
        }
    }

    componentWillUnmount() {
        clearInterval(this.currentInterval);
    }

    moveTo(idx: number, move: number) {
        this.setState({
            move: move
        }, () => {
            this.setState({
                currentContentIndex: idx
            });
        });
    }

    loop = () => {
        let nextItem = this.state.currentContentIndex + 1;
        if (nextItem >= this.state.contents.length) {
            nextItem = 0;
        }
        this.moveTo(nextItem, 1);
    };

    prev = (arg: React.MouseEvent<any>) => {
        arg.preventDefault();
        arg.stopPropagation();
        clearInterval(this.currentInterval);
        let newindex = this.state.currentContentIndex - 1;
        if (newindex < 0) {
            newindex = this.state.contents?.length - 1;
        }
        this.moveTo(newindex, newindex - this.state.currentContentIndex);
    };

    next = (arg: React.MouseEvent<any>) => {
        arg.preventDefault();
        arg.stopPropagation();
        clearInterval(this.currentInterval);
        let newindex = this.state.currentContentIndex + 1;
        if (newindex >= this.state.contents?.length) {
            newindex = 0;
        }
        this.moveTo(newindex, newindex - this.state.currentContentIndex);
    };

    loadItems(contentType: ICommunityContentType) {
        const filters: any = {
            spotlight: true
        };

        if (this.props.page.context.entityKind === "Topic" && this.props.page.context.entityid) {
            filters.topics = {
                $any: {
                    id: this.props.page.context.entityid
                }
            };
        }

        const nbItems = this.props.template.properties.maxItems || 6;
        const sort = (this.props.template?.properties?.sort as any)?.propName || "createdAt";
        const query: IInwinkEntityV3QueryOptions = {
            selects: {
                $all: true
            },
            page: {
                size: nbItems,
                index: 0
            },
            orders: [
                {
                    value: { [sort]: {} },
                    desc: (this.props.template?.properties?.sort as any)?.order === "desc"
                }
            ],
            filters: filters
        };

        if (this.props.match.params.groupid) {
            (query as any).groupId = this.props.match.params.groupid;
        }
        
        if (contentType !== "Topic" && contentType !== "Group" && contentType !== "Forum") {
            return contentQuery(this.props.community.requestManagers.apiFront, contentType, {
                ...query,
                selects: {
                    ...query.selects,
                    info: {
                        likes: true,
                        comments: true
                    },
                    topics: {
                        id: true,
                        title: true
                    }
                }
            });
        }

        return contentQuery(this.props.community.requestManagers.apiFront, contentType, query);
    }

    render() {
        let prev = null;
        let next = null;
        let content = null;

        if (this.state.contents?.length) {
            if (this.state.contents.length > 1) {
                prev = <div className="gotoprev">
                    <button type="button" onClick={this.prev}><i className="inwink-chevron-left" /></button>
                </div>;
                next = <div className="gotonext">
                    <button type="button" onClick={this.next}><i className="inwink-chevron-right" /></button>
                </div>;
            }
            const currentItem = this.state.contents[this.state.currentContentIndex];
            content = <SpotlightItem
                {...this.props}
                key={currentItem.id}
                item={currentItem}
                titleMax={titleMax}
                descMax={descMax}
                move={this.state.move}
                prevBtn={prev}
                nextBtn={next}
            />;
        }

        const withPager = this.state.contents?.length && this.state.contents.length > 1;

        return <DynamicBloc {...this.props}>
            <div className={"communityspotlights bloc-content" + (this.state.loading ? " loading" : "")}>
                <Placeholder
                    titleMax={titleMax}
                    descMax={descMax}
                />
                {this.state.contents?.filter((c) => !!c.herobanner?.url).map((c) => {
                    return <img src={c.herobanner.url} key={c.id} alt="" className="picturepreload" />;
                })}
                <div className={"items " + (!withPager ? "single" : "")}>
                    <AnimatePresence>
                        {content}
                    </AnimatePresence>
                </div>
                {withPager ? <div className="pager">{this.state.contents.map((c, idx) => {
                    const selected = idx === this.state.currentContentIndex;
                    return <div
                        key={c.id}
                        className={"pageritem clickable" + (selected ? " selected" : "")}
                        onClick={(arg) => {
                            arg.preventDefault();
                            arg.stopPropagation();
                            clearInterval(this.currentInterval);
                            this.moveTo(idx, idx - this.state.currentContentIndex);
                        }}
                    />;
                })}</div>
                    : null}
            </div>
        </DynamicBloc>;
    }
}

import * as React from 'react';
import { AsyncButton } from '@inwink/buttons/asyncbutton';
import { IEntityDetailActionProps } from '@@pages/entitydetail/entitydetail.actions';
import { like } from '@@community/api/contents';
import type { ICommunityContent } from '@@community/data';
import { AppTextLabel } from '@inwink/i18n';
import { actions as pageActions } from '@@services/pageservice';
import { connectwith } from '@inwink/react-utils/decorators/connectwith';
import { bindActionCreators } from 'redux';
import { userMessageActions } from '@@services/usermessageactions';
import {
    LikesPreview
} from '@@modules/community/features/feed/blocs/mainfeed/mainbloc/publications/publicationitem/likespreview';
import { MemberInfoContextSource } from '@@modules/community/components/memberinfocontextsource';
import { connect } from 'react-redux';
import { IMemberInfoContext, MemberInfoContext } from '@@modules/community/components/memberinfocontext';

import './like.less';

interface ILikeContentProps extends IEntityDetailActionProps {
    userMessageActions?: typeof userMessageActions;
}

interface ILikeContentState {
    likePreviewOpen?: boolean;
}

function LikeContentComponent(props: ILikeContentProps) {
    return <MemberInfoContextSource customcontext={props.customcontext}>
        <LikeContentAction {...props} />
    </MemberInfoContextSource>;
}

@connectwith(() => {
    return {
    };
}, (dispatch) => {
    return {
        pageActions: bindActionCreators(pageActions, dispatch),
    };
})

export class LikeContentAction extends React.PureComponent<ILikeContentProps, ILikeContentState> {
    constructor(props: ILikeContentProps) {
        super(props);
        this.state = {
            likePreviewOpen: false
        };
    }

    render() {
        const content: ICommunityContent = this.props.datacontext.entity;
        const nbLikes = content?.info?.likes || 0;

        const onCounterClick = (arg: React.MouseEvent<any>, memberContext: IMemberInfoContext) => {
            const p = {
                ...this.props,
                contentId: this.props.datacontext?.entity?.id,
                memberContext
            };
            this.setState({ likePreviewOpen: true });
            (this.props.userMessageActions?.showMessage(
                this.props.i18nHelper, LikesPreview, p, arg.target as HTMLElement, null, null, "likepreview-modal") as any)
                .then(() => {
                    this.setState({ likePreviewOpen: false });
                });
        };

        const onClick = (arg: React.MouseEvent<any>) => {
            arg.preventDefault();

            if (!content.isLiked) {
                return import("@@community/features/user/useractions").then((mod) => {
                    const targ = arg.target as HTMLElement;
                    return mod.userActions.promptUserAccess(this.props.i18nHelper,
                        targ)(this.props.store.dispatch, this.props.store.getState)
                        .then((canAct) => {
                            if (canAct) {
                                return like(
                                    this.props.community.requestManagers.apiFront,
                                    this.props.entityKind as any, this.props.entityId
                                ).then((res) => {
                                    this.props.pageActions.patchCurrentPageEntity({
                                        isLiked: true,
                                        info: Object.assign({}, this.props.datacontext.entity.info, {
                                            likes: res.likes
                                        })
                                    });
                                }, (err) => {
                                    console.error("error getting like", err);
                                });
                            }
                        });
                });
            }
        };

        if (!content.isLiked) {
            if (!this.props.entityId) {
                return null;
            }

            if (!content || content.disableLikes) {
                return null;
            }

            const classNames = ["like"];

            if (this.props.action?.displayOptions?.isSecondary) {
                classNames.push('lightbtn');
            }

            return <MemberInfoContext.Consumer>
                {(ctx) => <span className="content-like">
                    <span
                        className="like-counter"
                        onClick={nbLikes > 0 ? (arg) => onCounterClick(arg, ctx) : null}
                    >
                        <i className="inwink-thumbup-outline" />
                        <span>
                            {nbLikes}
                        </span>
                    </span>
                    <AsyncButton className={classNames?.join(' ')} onClick={onClick}>
                        {this.props?.action?.icon?.className
                            ? <i className={this.props.action.icon.className} />
                            : null}
                        <AppTextLabel i18n={
                            this.props?.i18nHelper?.translateBag(this.props?.action?.label)
                            || "community.content.actions.like"} />
                    </AsyncButton>
                </span>
                }
            </MemberInfoContext.Consumer>;
        }

        return null;
    }
}

function mapDispatchToProps(dispatch) {
    return {
        userMessageActions: bindActionCreators(userMessageActions, dispatch)
    };
}

export const LikeContent: new (any) => React.Component<ILikeContentProps, any> = connect(
    null,
    mapDispatchToProps
)(LikeContentComponent as any) as any;
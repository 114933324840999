import * as React from 'react';
import { AsyncButton } from '@inwink/buttons/asyncbutton';
import { IEntityDetailActionProps } from '@@pages/entitydetail/entitydetail.actions';
import { unlike } from '@@community/api/contents';
import type { ICommunityContent } from '@@community/data';
import { AppTextLabel } from '@inwink/i18n';
import { actions as pageActions } from '@@services/pageservice';
import { connectwith } from '@inwink/react-utils/decorators/connectwith';
import { bindActionCreators } from 'redux';
import { userMessageActions } from '@@services/usermessageactions';
import { MemberInfoContextSource } from '@@modules/community/components/memberinfocontextsource';
import { connect } from 'react-redux';
import {
    LikesPreview
} from '@@modules/community/features/feed/blocs/mainfeed/mainbloc/publications/publicationitem/likespreview';
import { IMemberInfoContext, MemberInfoContext } from '@@modules/community/components/memberinfocontext';

import './like.less';

interface IUnlikeContentProps extends IEntityDetailActionProps {
    userMessageActions?: typeof userMessageActions;
}

interface IUnlikeContentState {
    likePreviewOpen?: boolean;
}

function UnlikeContentComponent(props: IUnlikeContentProps) {
    return <MemberInfoContextSource customcontext={props.customcontext}>
        <UnlikeContentAction {...props} />
    </MemberInfoContextSource>;
}

@connectwith(() => {
    return {
    };
}, (dispatch) => {
    return {
        pageActions: bindActionCreators(pageActions, dispatch),
    };
})
export class UnlikeContentAction extends React.PureComponent<IUnlikeContentProps, IUnlikeContentState> {
    constructor(props: IUnlikeContentProps) {
        super(props);
        this.state = {
            likePreviewOpen: false
        };
    }

    render() {
        const content: ICommunityContent = this.props.datacontext.entity;
        const nbLikes = content?.info?.likes || 0;


        const onCounterClick = (arg: React.MouseEvent<any>, memberContext: IMemberInfoContext) => {
            const p = {
                ...this.props,
                contentId: this.props.datacontext?.entity?.id,
                memberContext
            };
            this.setState({ likePreviewOpen: true });
            (this.props.userMessageActions?.showMessage(
                this.props.i18nHelper, LikesPreview, p, arg.target as HTMLElement, null, null, "likepreview-modal") as any)
                .then(() => {
                    this.setState({ likePreviewOpen: false });
                });
        };

        const onClick = (arg: React.MouseEvent<any>) => {
            arg.preventDefault();

            if (content.isLiked) {
                return unlike(
                    this.props.community.requestManagers.apiFront, this.props.entityKind as any, this.props.entityId
                ).then((res) => {
                    this.props.pageActions.patchCurrentPageEntity({
                        isLiked: false,
                        info: Object.assign({}, this.props.datacontext.entity.info, {
                            likes: res.likes
                        })
                    });
                }, (err) => {
                    console.error("error getting like", err);
                });
            }
        };

        if (content.isLiked) {
            if (!this.props.entityId) {
                return null;
            }

            if (!content || content.disableLikes) {
                return null;
            }

            const classNames = ["checked unlike"];

            if (this.props.action?.displayOptions?.isSecondary) {
                classNames.push('lightbtn');
            }

            return <MemberInfoContext.Consumer>
                {(ctx) => <span className="content-like">
                    <span
                        className="like-counter"
                        onClick={nbLikes > 0 ? (arg) => onCounterClick(arg, ctx) : null}
                    >
                        <i className="inwink-thumbup-outline" />
                        <span>
                            {nbLikes}
                        </span>
                    </span>
                    <AsyncButton className={classNames?.join(' ')} onClick={onClick}>
                        {this.props?.action?.icon?.className
                            ? <i className={this.props?.action?.icon?.className} />
                            : null}
                        <AppTextLabel i18n={
                            this.props?.i18nHelper?.translateBag(this.props?.action?.label)
                            || "community.content.actions.like"} />
                    </AsyncButton>
                </span>
                }
            </MemberInfoContext.Consumer>;

        }

        return null;
    }
}

function mapDispatchToProps(dispatch) {
    return {
        userMessageActions: bindActionCreators(userMessageActions, dispatch)
    };
}

export const UnlikeContent: new (any) => React.Component<IUnlikeContentProps, any> = connect(
    null,
    mapDispatchToProps
)(UnlikeContentComponent as any) as any;
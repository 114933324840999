import type { ICommentsDatasource, ICommunityContentType, ICommunityGroup } from '@@community/data';
import { comment, latestComments, getComments, removeComment, updateComment } from '@@community/api/contents';
import { States } from '@@services/services';
import { getUrlPreview } from '@@modules/community/api/urlpreview';
import { getMembersToMention } from '@@modules/community/api/feed';

export function getCommentsDatasource(
    community: States.ICommunityState,
    user: States.IAppUserState,
    contentType: ICommunityContentType,
    contentId: string,
    group?: ICommunityGroup

) {
    const mgr = community.requestManagers.apiFront;
    const allowed = (memberId: string) => {
        if (memberId === user?.currentUser?.member?.id) {
            return true;
        }
        if (user?.currentUser?.member?.membershipLevel?.configuration?.isModerator) {
            return true;
        }
        return false;
    };

    const allowedEdit = (memberId: string) => {
        if (memberId === user?.currentUser?.member?.id) {
            return true;
        }
        return false;
    };

    const ds: ICommentsDatasource = {
        allowDelete: allowed,
        allowEdit: allowedEdit,
        removeComment: (commentId) => removeComment(mgr, contentType, contentId, commentId),
        updateComment: (commentId, text, links) => updateComment(mgr, contentType, contentId, commentId, text, links),
        addComment: (text, links, replyTo) => comment(mgr, contentType, contentId, text, links, replyTo),
        latestComments: () => latestComments(mgr, contentType, contentId),
        getComments: (query) => getComments(mgr, contentType, contentId, query),
        getUrlPreview: (finalUrl) => getUrlPreview(mgr, finalUrl),
        getMembersToMention: (query, callback) => getMembersToMention(mgr, query, callback, group)
    };

    return ds;
}
import * as React from 'react';
import { AsyncButton } from '@inwink/buttons/asyncbutton';
import { IEntityDetailActionProps } from '@@pages/entitydetail/entitydetail.actions';
import { like, unlike } from '@@community/api/contents';
import type { ICommunityContent } from '@@community/data';
import { AppTextLabel } from '@inwink/i18n';
import { IMemberInfoContext, MemberInfoContext } from '@@community/components/memberinfocontext';
import { MemberInfoContextSource } from '@@community/components/memberinfocontextsource';
import {
    LikesPreview
} from '@@modules/community/features/feed/blocs/mainfeed/mainbloc/publications/publicationitem/likespreview';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions as pageActions } from '@@services/pageservice';
import { userMessageActions } from '@@services/usermessageactions';
import { connectwith } from '@inwink/react-utils/decorators/connectwith';

import './like.less';

interface IContentLikeProps extends IEntityDetailActionProps {
    userMessageActions?: typeof userMessageActions;
}

interface IContentLikeState {
    likePreviewOpen?: boolean;
}

function ContentLikeComponent(props: IContentLikeProps) {
    return <MemberInfoContextSource customcontext={props.customcontext}>
        <ContentLikeContent {...props} />
    </MemberInfoContextSource>;
}

@connectwith(() => {
    return {
    };
}, (dispatch) => {
    return {
        pageActions: bindActionCreators(pageActions, dispatch),
    };
})

export class ContentLikeContent extends React.PureComponent<IContentLikeProps, IContentLikeState> {
    constructor(props: IContentLikeProps) {
        super(props);
        this.state = {
            likePreviewOpen: false
        };
    }

    render() {
        const content: ICommunityContent = this.props.datacontext.entity;
        const nbLikes = content?.info?.likes || 0;

        const onCounterClick = (arg: React.MouseEvent<any>, memberContext: IMemberInfoContext) => {
            const p = {
                ...this.props,
                contentId: this.props.datacontext?.entity?.id,
                memberContext
            };
            this.setState({ likePreviewOpen: true });
            (this.props.userMessageActions?.showMessage(
                this.props.i18nHelper, LikesPreview, p, arg.target as HTMLElement, null, null, "likepreview-modal") as any)
                .then(() => {
                    this.setState({ likePreviewOpen: false });
                });
        };

        const onClick = (arg: React.MouseEvent<any>) => {
            arg.preventDefault();
            if (this.props.entityId) {
                if (content.isLiked) {
                    return unlike(
                        this.props.community.requestManagers.apiFront, this.props.entityKind as any, this.props.entityId
                    ).then((res) => {
                        if (!content.info) {
                            content.info = {} as any;
                        }
                        content.info.likes = res?.likes;
                        this.props.pageActions.patchCurrentPageEntity({
                            isLiked: false,
                            info: Object.assign({}, this.props.datacontext.entity.info, {
                                likes: res.likes
                            })
                        });
                    }, (err) => {
                        console.error("error getting like", err);
                    });
                }

                return import("@@community/features/user/useractions").then((mod) => {
                    const targ = arg.target as HTMLElement;
                    return mod.userActions.promptUserAccess(this.props.i18nHelper,
                        targ)(this.props.store.dispatch, this.props.store.getState)
                        .then((canAct) => {
                            if (canAct) {
                                return like(this.props.community.requestManagers.apiFront,
                                    this.props.entityKind as any, this.props.entityId)
                                    .then((res) => {
                                        if (!content.info) {
                                            content.info = {} as any;
                                        }
                                        content.info.likes = res?.likes;
                                        this.props.pageActions.patchCurrentPageEntity({
                                            isLiked: true,
                                            info: Object.assign({}, this.props.datacontext.entity.info, {
                                                likes: res.likes
                                            })
                                        });
                                    }, (err) => {
                                        console.error("error getting like", err);
                                    });
                            }
                        });
                });
            }
        };

        if (!this.props.entityId) {
            return null;
        }

        if (!content || content.disableLikes) {
            return null;
        }

        return <MemberInfoContext.Consumer>
            {(ctx) => <span className="content-like">
                <span
                    className="like-counter"
                    onClick={nbLikes > 0 ? (arg) => onCounterClick(arg, ctx) : null}
                >
                    <i className="inwink-thumbup-outline" />
                    <span
                        className={`count ${this.state.likePreviewOpen ? "active" : ""}`}>
                        {nbLikes}
                    </span>
                </span>
                <AsyncButton className={"act-like" + (content.isLiked ? " checked" : "")} onClick={onClick}>
                    <i className={content.isLiked
                        ? "inwink-thumbup-full"
                        : "inwink-thumbup-outline"}
                    />
                    {content.isLiked
                        ? <AppTextLabel i18n="community.content.actions.unlike" />
                        : <AppTextLabel i18n="community.content.actions.like" />}
                </AsyncButton>
            </span>}

        </MemberInfoContext.Consumer>;
    }
}

function mapDispatchToProps(dispatch) {
    return {
        userMessageActions: bindActionCreators(userMessageActions, dispatch)
    };
}

export const ContentLike: new (any) => React.Component<IContentLikeProps, any> = connect(
    null,
    mapDispatchToProps
)(ContentLikeComponent as any) as any;

import type { ICommunityContentType } from '@@community/data';

export function getPageFor(contentType: ICommunityContentType | string, contentId: string) {
    if (contentType === "DocumentCommunity") {
        return "documents/" + contentId;
    }
    if (contentType === "IWEvent") {
        return "events/" + contentId;
    }
    if (contentType === "Group") {
        return "g/" + contentId;
    }
    if (contentType === "Company") {
        return "companies/" + contentId;
    }
    if (contentType === "CompanyOffering") {
        return "offerings/" + contentId;
    }
    
    return contentType.toLocaleLowerCase() + "s/" + contentId;
}
